import { Button } from 'primereact/button';

const original = [
        { 'value': 'N', 'label': 'New', 'icon': 'pi pi-book', 'class' :'p-button-text p-button-secondary' },
        { 'value': 'AP', 'label': 'Pending Approval', 'icon': 'pi pi-question', 'class' :'p-button-text p-button-warning'  },
        { 'value': 'A', 'label': 'Approval', 'icon': 'pi pi-thumbs-up', 'class' :'p-button-text p-button-success'  },
        { 'value': 'P', 'label': 'Posted', 'icon': 'pi pi-check-square', 'class' :'p-button-text p-button-success'  },
        { 'value': 'E', 'label': 'Edited', 'icon': 'pi pi-pencil', 'class' :'p-button-text p-button-warning'  },
        { 'value': 'I', 'label': 'Inactive', 'icon': 'pi pi-lock', 'class' :'p-button-text p-button-danger'  },
        { 'value': 'C', 'label': 'Closed', 'icon': 'pi pi-dollar', 'class' :'p-button-text p-button-success'   },
        { 'value': 'R', 'label': 'Rejected', 'icon': 'pi pi-thumbs-down', 'class' :'p-button-text p-button-danger'  },
        { 'value': 'NR', 'label': 'Rejected', 'icon': 'pi pi-thumbs-down', 'class' :'p-button-text p-button-secondary'  },
        { 'value': 'X', 'label': 'Cancelled', 'icon': 'pi pi-trash', 'class' :'p-button-text p-button-warning'  },
        { 'value': 'MA', 'label': 'Approved (M)', 'icon': 'pi pi-thumbs-up', 'class' :'p-button-text p-button-info'  },
        { 'value': 'MC', 'label': 'Closed (M)', 'icon': 'pi pi-dollar', 'class' :'p-button-text p-button-danger'  },
        { 'value': 'MX', 'label': 'Cancelled (M)', 'icon': 'pi pi-trash', 'class' :'p-button-text p-button-danger'  },
];

export function refGetRecStatusLabel(recStatus)  {
    let statusObj = original.filter( key => key.value === recStatus)[0]

    if (statusObj) {
        return statusObj.label
    }
    else { 
        return recStatus
    }
}

const refGetRecStatusIcon = (recStatus) => {
    let statusObj = original.filter( key => key.value === recStatus)[0]

    if (statusObj) {
        return statusObj.icon
    }
    else { 
        return null
    }
}

export function refGetRecStatusClass(recStatus) {
    let statusObj = original.filter( key => key.value === recStatus)[0]

    if (statusObj) {
        return statusObj.class
    }
    else { 
        return 'p-button-text p-button-secondary' 
    }
}

export function refGetRecStatusButton(recStatus) {
    return (refGetRecStatusIcon(recStatus) === null)?
        <Button type='button' label={recStatus} className={refGetRecStatusClass(recStatus)} tooltip={refGetRecStatusLabel(recStatus)} />
    : 
        <Button type='button' icon={refGetRecStatusIcon(recStatus)} className={refGetRecStatusClass(recStatus)} tooltip={refGetRecStatusLabel(recStatus)} />
}

