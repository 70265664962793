import { useHistory } from "react-router-dom";
import React, { useContext, useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";

import { saveAs } from "file-saver";

// import { dbGetSpend } from '../services/SpendService';
import { dbGetUser } from "../services/UserService";
import { refGetRecStatusButton } from "../services/ReferenceService";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { InputText } from 'primereact/inputtext';

import { Dropdown } from "primereact/dropdown";
import { LoginContext } from "../contexts/LoginContext";

import { FilterMatchMode, FilterOperator } from "primereact/api";

export default function UserGrid() {
  const { profile, setProfile } = useContext(LoginContext);
  const history = useHistory();
  const toast = useRef(null);

  const [loading, setLoading] = useState(false);
  // let selectedCustomers = null
  const [spend, setUser] = useState([]);

  const [showFilter, setShowFilter] = useState(false);
  // const [recStatus, setRecStatus] = useState(null);

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filterSet: false,
    filters: {
      id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      usrID: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      usrNme: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      usrLayout: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      usrViewBudgetCom: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      recStatus: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    },
    sortMeta: [],
  });

  // let multiSortMeta = [];

  // const dt = useRef(null);

  const header = (
    <div className="table-header">
      User List
      <div className="p-tablebuttonset">
        {/* <Button type="button" label="" icon="pi pi-file-excel" className="p-button-rounded p-button-warning" onClick={(e) => exportExcel(e)} /> */}
        <Button
          type="button"
          tooltip="New"
          icon="pi pi-plus"
          className="p-button-rounded p-button-success"
          onClick={() => (window.location = "#/cwUser")}
        />
        <Button
          type="button"
          tooltip="Filter"
          icon={showFilter ? "pi pi-filter-slash" : "pi pi-filter"}
          className="p-button-rounded p-button-warning"
          onClick={() => setShowFilter(!showFilter)}
        />
        <Button
          type="button"
          tooltip="Refresh"
          icon="pi pi-refresh"
          className="p-button-rounded p-button-success"
          onClick={(e) => tableRefresh("list")}
        />
        <Button
          type="button"
          tooltip="Export to Excel"
          label="Export"
          icon="pi pi-file-excel"
          className="p-button-rounded p-button-warning"
          onClick={(e) => exportExcel(e)}
        />
      </div>
    </div>
  );

  const exportExcel = () => {
    tableRefresh("export");
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  // const dateBodyTemplate = (rowData, field) => {
  //     if (rowData[`${field}`] === undefined) {
  //         return null
  //     } else {
  //         let dateWithouthSecond = new Date(rowData[`${field}`]);
  //         return dateWithouthSecond.toLocaleString("en-GB", { dateStyle: "medium" })
  //     }
  // }

  // const filterFromBackend = () => {
  //     alert('go get new data from database');
  //     return false
  // }

  // const amountBodyTemplate = (rowData, field) => {
  //     let value = rowData[`${field}`]
  //     if (value === undefined)
  //         return null
  //     return value.toLocaleString(undefined,
  //         { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 });
  // }

  const recStatusBodyTemplate = (rowData) => {
    // switch (rowData.recStatus) {
    //     case 'N':
    //         return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)} className="p-button-text p-button-secondary" tooltip="New" />
    //     case 'AP':
    //         return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)} className="p-button-text p-button-warning" tooltip="Pending Approval" />
    //     case 'A':
    //         return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)}  className="p-button-text p-button-success" tooltip="Approved" />
    //     case 'R':
    //         return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)} className="p-button-text p-button-danger" tooltip="Rejected" />
    //     case 'X':
    //         return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)} className="p-button-text  p-button-warning" tooltip="Cancelled" />
    //     case 'C':
    //         return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)} className="p-button-text p-button-info" tooltip="Closed" />
    //     default:
    return refGetRecStatusButton(rowData.recStatus);
    // }
  };

  // const recStatusBodyTemplate = (rowData) => {
  //     switch (rowData.recStatus) {
  //         case 'N':
  //             return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)} className="p-button-text p-button-secondary" tooltip="New" />
  //         case 'AP':
  //             return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)} className="p-button-text p-button-warning" tooltip="Pending Approval" />
  //         case 'A':
  //             return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)}  className="p-button-text p-button-success" tooltip="Approved" />
  //         case 'R':
  //             return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)} className="p-button-text p-button-danger" tooltip="Rejected" />
  //         case 'X':
  //             return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)} className="p-button-text  p-button-warning" tooltip="Cancelled" />
  //         case 'C':
  //             return <Button type="button" icon={refGetRecStatusClass(rowData.recStatus)} className="p-button-text p-button-info" tooltip="Closed" />
  //         default:
  //             return <span>{rowData.recStatus}</span>;
  //     }
  // }

  const recStatusFilterTemplate = (recStatus) => {
    switch (recStatus) {
      case "N":
        return (
          <Button
            type="button"
            icon="pi pi-book"
            className="p-button-text p-button-secondary"
            tooltip="New"
          />
        );
      case "AP":
        return (
          <Button
            type="button"
            icon="pi pi-question"
            className="p-button-text p-button-warning"
            tooltip="Pending Approval"
          />
        );
      case "A":
        return (
          <Button
            type="button"
            icon="pi pi-thumbs-up"
            className="p-button-text p-button-success"
            tooltip="Approved"
          />
        );
      case "R":
        return (
          <Button
            type="button"
            icon="pi pi-thumbs-down"
            className="p-button-text p-button-danger"
            tooltip="Rejected"
          />
        );
      case "X":
        return (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-text  p-button-warning"
            tooltip="Cancelled"
          />
        );
      case "C":
        return (
          <Button
            type="button"
            icon="pi pi-lock"
            className="p-button-text p-button-info"
            tooltip="Closed"
          />
        );
      default:
        return <span>{recStatus}</span>;
    }
  };

  // <React.Fragment></React.Fragment>
  // const recStatusIcon = (status) => {
  //     switch (status) {
  //         case 'N':
  //             return "pi pi-book"
  //         case 'AP':
  //             return "pi pi-question"
  //         case 'A':
  //             return "pi pi-thumbs-up"
  //         case 'R':
  //             return 'pi pi-thumbs-down'
  //         case 'X':
  //             return "Cancelled"
  //         case 'C':
  //             return "pi pi-lock"
  //         default:
  //             return status
  //     }
  // }
  // const recStatusClass = (status) => {
  //     switch (status) {
  //         case 'N':
  //             return "pi pi-book"
  //         case 'AP':
  //             return "pi pi-question"
  //         case 'A':
  //             return "pi pi-thumbs-up"
  //         case 'R':
  //             return 'pi pi-thumbs-down'
  //         case 'X':
  //             return "Cancelled"
  //         case 'C':
  //             return "pi pi-lock"
  //         default:
  //             return status
  //     }
  // }
  const recStatusDesc = (status) => {
    switch (status) {
      case "N":
        return "New";
      case "AP":
        return "Pending Approval";
      case "A":
        return "Approved";
      case "R":
        return "Rejected";
      case "X":
        return "Cancelled";
      case "C":
        return "Closed";
      default:
        return status;
    }
  };

  // let recStatuses = [
  //     { label: 'All Status', value: null },
  //     { label: 'New', value: 'N' },
  //     { label: 'Pending', value: 'AP' },
  //     { label: 'Approved', value: 'A' },
  //     { label: 'Rejected', value: 'R' },
  //     { label: 'Closed', value: 'C' },
  //     { label: 'Cancelled', value: 'X' }
  // ];

  // const onStatusChange = (event) => {
  //     dt.current.filter(event.value, 'recStatus', 'equals');
  //     setRecStatus(event.value);
  // }
  const tableRefresh = async (qryTyp) => {
    // const tableRefresh = (qryTyp) => {
    // Sort Data
    let wsOrder = "";
    let sqlOrder = "";

    lazyParams.sortMeta.forEach((fld, index) => {
      sqlOrder = [sqlOrder, index === 0 ? "" : " , ", fld.field].join(" ");
      sqlOrder = [sqlOrder, fld.order === 1 ? "" : " desc"].join("");
    });

    if (sqlOrder !== "") {
      wsOrder = sqlOrder;
    }

    // for (const fld in lazyParams.sortMeta) {
    //   sqlOrder = [
    //     sqlOrder,
    //     fld === 0 ? "" : " , ",
    //     lazyParams.sortMeta[fld].field,
    //   ].join(" ");
    //   sqlOrder = [
    //     sqlOrder,
    //     lazyParams.sortMeta[fld].order === 1 ? "" : " desc",
    //   ].join("");
    // }
    // if (sqlOrder !== "") {
    //   wsOrder = sqlOrder;
    // }

    let wsFilter = "";

    if (showFilter) {
      for (const fld in lazyParams.filters) {
        let sqlFilter = "";
        for (const v in lazyParams.filters[fld]["constraints"]) {
          if (lazyParams.filters[fld]["constraints"][v].value !== null) {
            let fldOperator = lazyParams.filters[fld]["operator"];
            let fldFilter = getFilterOperator(
              lazyParams.filters[fld]["constraints"][v].matchMode,
              lazyParams.filters[fld]["constraints"][v].value
            );
            sqlFilter = [
              sqlFilter,
              v === 0 ? "" : fldOperator,
              fld,
              fldFilter,
            ].join(" ");
          }
        }
        if (sqlFilter !== "") {
          // wsFilter =  [wsFilter , (wsFilter==='')?'': sqlFilter  ].join('')
          wsFilter = [wsFilter, wsFilter === "" ? "" : "", sqlFilter].join("");
        }
      }
    }
    let pageNo = parseInt(lazyParams.page) + 1;
    // setLoading( true)
    // console.log("get users ")

    await dbGetUser({
      query:
        "type=" +
        qryTyp +
        "&page=" +
        pageNo +
        "&rowLimit=" +
        lazyParams.rows +
        "&filter=" +
        wsFilter +
        "&order=" +
        wsOrder,
    })
      .then((result) => {
        if (result.status === 201) {
          // console.log("reuslt ", result)
          if (qryTyp === "list") {
            setUser(result.data);
            setTotalRecords(result.recCount);
            // calcRowTotal()
          } else {
            // exportData( result.data )
            import("xlsx").then((xlsx) => {
              const worksheet = xlsx.utils.json_to_sheet(result.data);
              const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
              };
              const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
              });
              saveAsExcelFile(excelBuffer, "User");
            });
          }
          setLoading(false);
        } else {
          setUser([]);
        }
      })
      .catch((rejected) => {
        console.log("Budget Rejected : ", rejected);
      });

    // dbGetUser({ query: "type=" + qryTyp + "&page=" + pageNo + "&rowLimit=" + lazyParams.rows + "&filter=" + wsFilter + "&order=" + wsOrder }, (result) => {
    //      console.log("reuslt ", result)

    //     if (result.status === 201) {
    //         console.log("reuslt ", result)
    //         if ( qryTyp === 'list') {
    //             setUser(result.data);
    //             setTotalRecords(result.recCount);
    //             // calcRowTotal()
    //         } else {
    //             // exportData( result.data )
    //             import('xlsx').then(xlsx => {
    //                 const worksheet = xlsx.utils.json_to_sheet(result.data);
    //                 const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //                 const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //                 saveAsExcelFile(excelBuffer, 'spend');
    //              });
    //         }
    //         setLoading( false )
    //     } else {
    //         setUser([]);
    //     }
    // });
  };

  const onTablePage = (event) => {
    event.filters = lazyParams.filters;
    event.sortMeta = lazyParams.sortMeta;
    setLazyParams(event);
  };

  const onTableSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);

    setLazyParams((prevState) => {
      return {
        ...prevState,
        sortMeta: [{ field: event.sortField, order: event.sortOrder }],
      };
    });
  };

  const onFilter = (event) => {
    setLazyParams((prevState) => {
      return {
        ...prevState,
        filters: event.filters,
      };
    });
  };

  // const calcRowTotal = () => {
  //     let spendData =spend
  //     let rowAmt = 0
  //     let rowProv = 0
  //     let rowBal = 0
  //     spendData.reduce((a, v) => rowAmt = a + v.amt, 0)
  //     spendData.reduce((a, v) => rowProv = a + v.amt, 0)
  //     spendData.reduce((a, v) => rowBal = a + v.amt, 0)

  //     setUser({ ...spend, totalAmt: rowAmt, totalProv: rowProv, totalBal: rowBal })
  // }

  // const footerTemplate =
  //   <div style={{ height: 30 }}>
  //     <div style={{height: 30, width: '100%', float: 'left' }}>
  //         <div style={{ height: 30, width: '60%', float: 'left'}}>Total </div>
  //         <div style={{ height: 30, width: '15%', float: 'left' }}>
  //             <InputText
  //                 value={spend.provTotal}
  //                 style={{ marginTop: -24, textAlign: 'right' }}
  //                 className="p-tableFooter"
  //                 readOnly mode="decimal"
  //                 minFractionDigits={2} />
  //         </div>
  //         <div style={{ height: 30, width: '15%', float: 'left'}}>
  //             <InputText
  //                  value={spend.balTotal}
  //                 style={{ marginTop: -24, textAlign: 'right'   }}
  //                 className="p-tableFooter"
  //                 readOnly mode="decimal"
  //                 minFractionDigits={2} />
  //         </div>
  //     </div>
  // </div>

  const getFilterOperator = (operator, value) => {
    let rtnVal = null;
    switch (operator) {
      case "startsWith":
        rtnVal = " like '" + value + "^'";
        break;
      case "endsWith":
        rtnVal = " like '^" + value + "'";
        break;
      case "contains":
        rtnVal = " like '^" + value + "^'";
        break;
      case "notContains":
        rtnVal = " not like '^" + value + "^'";
        break;
      case "equals":
        rtnVal = " = '" + value + "'";
        break;
      case "notEquals":
        rtnVal = " != '" + value + "'";
        break;
      case "gt":
        rtnVal = " > " + value;
        break;
      case "lt":
        rtnVal = " < " + value;
        break;
      case "gte":
        rtnVal = " >= " + value;
        break;
      case "lte":
        rtnVal = " <= " + value;
        break;
      default:
        rtnVal = operator;
    }
    return rtnVal;
  };

  const onRowDblclick = (e) => {
    setProfile({ ...profile, lazyParams: lazyParams, showFilter: showFilter });
    history.push("/cwUser?id=" + e.data.id, lazyParams);
  };

  const filterStatus = ["N", "AP", "A", "R", "C"];

  const statusItemTemplate = (option) => {
    // let statusIcon = recStatusBodyTemplate(option)
    return (
      <div className="p-multiselect-representative-option">
        {recStatusFilterTemplate(option)}
        {recStatusDesc(option)}
      </div>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={filterStatus}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  useEffect(() => {
    setProfile({ ...profile, activeMenuName: "User List" });

    if (typeof profile.lazyParams !== "undefined") {
      setLazyParams(profile.lazyParams);
      setShowFilter(true);
      setProfile({ ...profile, lazyParams: undefined });
    }
    tableRefresh("list");
  }, [lazyParams]);

  return (
    <div>
      <div className="p-md-12 p-sm-12">
        <div className="card card-w-title">
          <div className="p-grid">
            <div className="p-md-12 p-sm-12">
              <DataTable
                value={spend}
                style={{ marginBottom: "20px" }}
                lazy
                first={lazyParams.first}
                totalRecords={totalRecords}
                onPage={onTablePage}
                onSort={onTableSort}
                sortField={sortField}
                sortOrder={sortOrder}
                multiSortMeta={lazyParams.sortMeta}
                loadingIcon="pi pi-spinner"
                loading={loading}
                className="p-datatable-sm"
                responsiveLayout="scroll"
                paginator
                rowHover
                onRowDoubleClick={(e) => onRowDblclick(e)}
                onFilter={onFilter}
                filters={lazyParams.filters}
                header={header}
                filterDisplay="menu"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={lazyParams.rows}
                rowsPerPageOptions={[10, 20, 50, 100]}
              >
                <Column
                  field="id"
                  header="id"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "80px" }}
                />
                <Column
                  field="usrID"
                  header="User ID"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "1%" }}
                />
                <Column
                  field="usrNme"
                  header="User Name"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "15%" }}
                />
                <Column
                  field="usrEmail"
                  header="eMail"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "15%" }}
                />
                <Column
                  field="usrMgr"
                  header="Manager"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "10%" }}
                />
                <Column
                  field="usrMenu"
                  header="Menu"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "10%" }}
                />
                <Column
                  field="usrBudget"
                  header="Budget Holder"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "10%" }}
                />
                <Column
                  field="usrBudget"
                  header="View Budget"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "5%" }}
                />
                <Column
                  field="usrViewBudgetCom"
                  header="View Company"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "5%" }}
                />

                <Column
                  field="recStatus"
                  filterMenuStyle={{ width: "14rem" }}
                  filter={showFilter}
                  className="columnCenter"
                  filterElement={statusFilterTemplate}
                  sortable={true}
                  style={{ width: "30px" }}
                  body={(rowData) => recStatusBodyTemplate(rowData)}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
}
