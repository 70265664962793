import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import * as Constants from "../services/dbConnection";

import { FileUpload } from "primereact/fileupload";

import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import { dbGetPDList } from "../services/DistributorService";
import { dbGetCstList } from "../services/CustomerService";
import { dbGetTypList } from "../services/SpendTypeService";
import { dbGetItmList } from "../services/ItemService";
import { DBGetAnp, dbSetSpend } from "../services/SpendService";
import { dbGetFileList, dbGetFileDownload } from "../services/FileService";
import { refGetRecStatusButton } from "../services/ReferenceService";

import classnames from "classnames";

// import classnames  from 'classnames ';
// import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
// import { InputText } from 'primereact/inputtext';

import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";

import { Button } from "primereact/button";

// import { AppFooter } from '../AppFooter';

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { LoginContext } from "../contexts/LoginContext";
import { Dropdown } from "primereact/dropdown";

export default function AnpRequest(props) {
  const history = useHistory();

  const toast = useRef(null);
  const { profile, setProfile } = useContext(LoginContext);
  // const fileuploader = useRef('ref') ;
  const fileuploader = useRef();

  // console.log("AnpRequest profile ", profile)
  // const anpCode = "ANP-21-000001"
  const defaultValues = {
    pd: null,
    dte: new Date(),
    cst: null,
    typ: null,
    dteFrom: new Date(),
    dteTo: new Date(),
    dtePay: new Date(),
    rmk: "",
    amt: 0,
    spend: [],
    cn: [],
    jv: [],
    prjSls: 0,
    prjROI: 0,
    filelist: [],
    filelist_Ori: [],
    recActionReason: "",
    recStatus: "N",
    recCreatedBy: null,
    recCreatedOn: null,
    recModifiedBy: null,
    recModifiedOn: null,
  };

  const budgetDefault = {
    opn: 0,
    all: 0,
    avl: 0,
    req: 0,
    bal: 0,
  };

  const {
    control,
    formState: { errors },
    setError,
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm({ defaultValues });
  // const [formShowMessage, setFormShowMessage] = useState(false);

  const [rec, setRec] = useState({
    id: null,
    recStatus: "N",
    readOnly: false,
    adminEdit: false,
    showFinanceAppr: false,
    showFinanceDiv: false,
  });
  const [activeTable, setActiveTable] = useState({});
  const [budget, setBudget] = useState(budgetDefault);
  const [budgetCom, setBudgetCom] = useState(budgetDefault);

  const [recID, setRecID] = useState(-1);

  const [pdOptions, setDistributors] = useState({});
  const [typOptions, setSpendType] = useState({});
  const [itmOptions, setItem] = useState({});
  // const [cstOptions, setCustomers] = useState({});
  const [cstFiltered, setCstFiltered] = useState(null);

  const [recActionReason, setrecActionReason] = useState();
  const [recAction, setrecAction] = useState();
  // const [recEditAdmin, setRecEditAdmin] = useState(false) ;

  const [showDelProductDialog, setShowDelProductDialog] = useState(false);
  // console.log("showDelProductDialog ", showDelProductDialog)
  const [showDelFileDialog, setShowDelFileDialog] = useState(false);
  const [showReasonDialog, setShowReasonDialog] = useState(false);

  const [uiEdit, setUiEdit] = useState("0");
  const [uiCopy, setUiCopy] = useState("1");
  const [uiSave, setUiSave] = useState("0");
  const [uiSubmit, setUiSubmit] = useState("0");
  const [uiApprove, setUiApprove] = useState("0");
  const [uiReject, setUiReject] = useState("0");
  const [uiClose, setUiClose] = useState("0");
  const [uiCancel, setUiCancel] = useState("0");
  const [uiCancelUndo, setUiCancelUndo] = useState("0");

  const [isApprover, setIsApprover] = useState(false);

  const authUsrRole = ["admin", "finAdmin"];

  const getRecStatus = (recStatus) => {
    switch (recStatus) {
      case "N":
        return <i className="pi pi-book p-icon-secondary"> New </i>;
      case "AP":
        return (
          <i className="pi pi-question p-icon-warning"> Pending Approval </i>
        );
      case "A":
        return <i className="pi pi-thumbs-up p-icon-success"> Approved </i>;
      case "R":
        return <i className="pi pi-thumbs-down p-icon-danger"> Rejected </i>;
      case "X":
        return <i className="pi pi-trash p-icon-warning"> Cancelled </i>;
      case "MX":
        return (
          <i className="pi pi-trash p-icon-warning"> Cancelled (Manually) </i>
        );
      case "C":
        return <i className="pi pi-lock p-icon-info"> Closed </i>;
      case "MA":
        return <i className="pi pi-lock p-icon-info"> Open </i>;
      case "MC":
        return <i className="pi pi-lock p-icon-info"> Close</i>;
      default:
        return <span>{recStatus}</span>;
    }
  };

  //
  // Form Control -------------------------------------------------------------------------
  //
  const onActionButtonClick = (action) => {
    setrecAction(action);
    if (
      action === "Reject" ||
      action === "Close" ||
      action === "Cancel" ||
      action === "Cancel_Undo"
    ) {
      setShowReasonDialog(true);
    } else if (action === "Edit") {
      setRec({ ...rec, readOnly: false, adminEdit: true });
      setUiSave("1");
      setUiSubmit("1");
    } else if (action === "PageBack") {
      history.goBack();
    } else if (action === "Copy") {
      let data = getValues();
      data.id = null;
      data.recStatus = "N";
      data.cn = [];
      data.jv = [];
      data.filelist = [];
      data.filelist_Ori = [];
      data.readOnly =
        data.recStatus === "N" || data.recStatus === "R" ? false : true;
      data.showFinanceAppr = data.recStatus !== "N" ? true : false;
      data.showFinanceDiv =
        data.recStatus === "A" ||
        data.recStatus === "C" ||
        data.recStatus === "X"
          ? true
          : false;
      data.recCreatedOn = null;
      data.recModifiedBy = null;
      data.recModifiedOn = null;
      setRec({
        id: data.id,
        recStatus: data.recStatus,
        readOnly:
          data.recStatus === "N" || data.recStatus === "R" ? false : true,
        adminEdit: false,
        showFinanceAppr: data.recStatus !== "N" ? true : false,
        showFinanceDiv:
          data.recStatus === "A" ||
          data.recStatus === "C" ||
          data.recStatus === "X"
            ? true
            : false,
      });
      setUiEdit("0");
      setUiCopy("0");
      setUiSave("1");
      setUiSubmit("1");
      reset(data);
    } else {
      setValue("action", action);
    }
  };

  const onSubmit = (data) => {
    //  alert("onsubmit ", data.id)
    setRecID(data.id);
    if (rec.adminEdit) {
      data.action = "Edit";
    }

    // let oldData = getValues()
    // console.log("submit filelist", data.filelist)
    // console.log("submit filelist_Ori " , data.filelist_Ori) ;

    if (data.cst.value === undefined) {
      setError("cst", {
        type: "manual",
        message: "Please select customer from list",
        shouldFocus: true,
      });
    } else {
      dbSetSpend(data, (result) => {
        if (data.id === undefined || data.id === null) {
          data.id = result.recID;
          setRecID(result.recID);
        }
        fileuploader.current.upload();

        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "A&P Request " + data.id + " saved",
          life: 6000,
        });

        reset({});
        history.goBack();
      });
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  // const getROI = (data) => {
  //     return getValues("prjSls") / budget.req
  // } ;

  //
  // Autocorrect - Customer ---------------------------------------------------------------------------------------------
  //
  const cstSearch = (event) => {
    const queryCst = event.query;
    const queryPD = getValues("pd");
    setTimeout(() => {
      // let param = Object
      // param.query = { query: "type=list&rowLimit=5&udcpd eq ''EHM-HQ'" }
      dbGetCstList(
        {
          query:
            "type=list&rowLimit=10&filter=udcpd eq '" +
            queryPD +
            "' AND udcdsc like '^" +
            queryCst +
            "^' ",
        },
        (result) => {
          if (result.status === 201) {
            // setCustomers(result.data);
            setCstFiltered(result.data);
          }
        }
      );
    }, 250);
  };

  //
  // Spend Table --------------------------------------------------------------------------------
  //
  const dropdownEditor = (productKey, props, field) => {
    return (
      <Dropdown
        value={props.rowData["itm"]}
        style={{ width: "100%" }}
        options={itmOptions}
        optionLabel="label"
        optionValue="value"
        disabled={rec.readOnly}
        onChange={(e) => onDropdownValueChange(productKey, props, e)}
      />
    );
  };

  const onDropdownValueChange = (productKey, props, e) => {
    let updatedProducts = [props.props.value];
    updatedProducts[0][props.rowIndex][props.field] = e.value;
    return e.value;
  };

  const priceEditor = (productKey, props) => {
    return (
      <InputNumber
        value={props.rowData[productKey]}
        disabled={rec.readOnly}
        onValueChange={(e) => onEditorValueChange(productKey, props, e.value)}
        mode="decimal"
        minFractionDigits={2}
        maxFractionDigits={2}
      />
    );
  };

  // const textEditor = (productKey, props) => {
  //     return <InputText value={props.rowData[productKey]}
  //         onChange={(e) => onTextValueChange(productKey, props, e)}
  //     />
  // }

  const dateEditor = (productKey, props) => {
    return (
      <Calendar
        id={props.rowData[productKey]}
        value={props.rowData[productKey]}
        onChange={
          (e) => onDateValueChange(productKey, props, e)
          // console.log("e value is ", e.value)
        }
        // onChange={(e) => onChange(e.value)}
        dateFormat="dd-M-yyyy"
        mask="99/99/9999"
        showIcon
        showButtonBar
      />
    );
  };

  const onFieldValueChange = (fieldName, props, e) => {
    //    if (fieldName == "prjSls") {
    //         let reqAmt = getValues("amt")
    //         setValue(fieldName, e.value)
    //         setROI( e.value, reqAmt)
    //    }
    return e.value;
  };

  const setROI = (prjSls, reqAmt) => {
    // console.log(prjSls, budReq)

    setValue("prjROI", Math.round((reqAmt / prjSls) * 10000) / 100);
    return;
  };

  const onDateValueChange = (productKey, props, e) => {
    if (e.value !== null) {
      let updatedProducts = [props.props.value];
      updatedProducts[0][props.rowIndex][props.field] =
        e.target.value.toLocaleString("en-GB", { dateStyle: "medium" });
    }
    return e.value;
  };

  // const onTextValueChange = (productKey, props, e) => {
  //     let updatedProducts = [props.props.value];

  //     updatedProducts[0][props.rowIndex][props.field] = e.target.value;
  //     return e.target.value;
  // }

  const fileSizeBodyTemplate = (rowData, field) => {
    let value = rowData[`${field}`] / 1024;
    let uom = " KB";
    if (value > 1024) {
      uom = " MB";
      value = value / 1024;
    }

    if (value === undefined || value === null) return null;
    return (
      value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + uom
    );
  };

  const priceBodyTemplate = (rowData, field) => {
    let value = rowData[`${field}`];
    if (value === undefined || value === null) return null;
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const percentageBodyTemplate = (rowData, field) => {
    let value = rowData[`${field}`];
    if (value === undefined || value === null) return null;
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const dateBodyTemplate = (rowData, field) => {
    if (rowData[`${field}`] === undefined || rowData[`${field}`] === null) {
      return null;
    } else {
      let dateWithouthSecond = new Date(rowData[`${field}`]);
      return dateWithouthSecond.toLocaleString("en-US", {
        dateStyle: "medium",
      });
    }
  };

  const datetimeBodyTemplate = (rowData, field) => {
    if (rowData[`${field}`] === undefined || rowData[`${field}`] === null) {
      return null;
    } else {
      let dateWithouthSecond_field = new Date(rowData[`${field}`]);
      // dateWithouthSecond_field = dateWithouthSecond_field.
      //  let dateWithouthSecond = new Date(Date.UTC(2012, 11, 20, 0, 0, 0));

      // dateWithouthSecond.toLocaleString('en-US', { timeZone: 'America/Paramaribo'})

      // console.log("field", dateWithouthSecond_field)
      // console.log("utc", dateWithouthSecond)

      return dateWithouthSecond_field.toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "medium",
      });
    }
  };
  const onEditorValueChange = (field, props, value) => {
    // let updatedProducts = [...props.value];
    // updatedProducts[props.rowIndex][props.field] = value;
    // console.log(field)

    let updatedProducts = [props.props.value];
    updatedProducts[0][props.rowIndex][props.field] = value;

    let reqAmt = updatedProducts[0][props.rowIndex]["amt"];
    let prjSls = updatedProducts[0][props.rowIndex]["prjSls"];

    updatedProducts[0][props.rowIndex]["prjROI"] =
      Math.round((reqAmt / prjSls) * 10000) / 100;

    calcRowTotal(field);
  };

  const header_prd = (
    <div>
      <Button
        type="button"
        icon="pi pi-plus"
        className="p-button-rounded"
        onClick={() => addProduct("spend")}
        tooltip="Add product"
      />
    </div>
  );

  // const header_jv = (
  //     <div>
  //         <Button type="button" icon="pi pi-plus" className="p-button-rounded" onClick={() => addProduct('jv')} tooltip="Add JV" />
  //     </div>
  // );

  // const header_cn = (
  //     <div>
  //         <Button type="button" icon="pi pi-plus" className="p-button-rounded" onClick={() => addProduct('cn')} tooltip="Add CN" />
  //     </div>
  // );

  const calcRowTotal = (field) => {
    let spendData = getValues("spend");

    let rowAmt = 0;
    let rowPrjSls = 0;
    // if (field === 'amt') {
    spendData.reduce((a, v) => (rowAmt = a + v.amt), 0);
    setValue("amt", rowAmt);
    // } else {
    spendData.reduce((a, v) => (rowPrjSls = a + v.prjSls), 0);
    setValue("prjSls", rowPrjSls);
    // }

    // let prjSls = getValues("prjSls")
    setROI(rowPrjSls, rowAmt);
    setBudget({ ...budget, req: rowAmt, bal: budget.avl - rowAmt });
  };

  const footerTemplate = (
    <div style={{ height: 120 }}>
      <div style={{ height: 40, width: "100%", float: "left" }}>
        {/* <div style={{ height: 40, width: '82%', float: 'left'}}>Total </div>
            <div style={{ height: 40, width: '18%', float: 'left' }}>
                <InputNumber value={budget.req}
                    style={{ marginTop: -24, textAlign: 'right' }}
                    className="p-tableFooter"
                    readOnly mode="decimal"
                    minFractionDigits={2} />
            </div> */}
        <div style={{ height: 40, width: "38%", float: "left" }}></div>
        <div style={{ height: 40, width: "62%", float: "left" }}>
          <div className="p-inputgroup">
            <span
              className="p-inputgroup-addon p-inputgroup_custom"
              style={{ width: "80%", fontWeight: "bold" }}
            >
              <label htmlFor="pd">Projected Sales *</label>
              {getFormErrorMessage("prjSls")}
            </span>

            <Controller
              name="prjSls"
              control={control}
              rules={{ required: "is required." }}
              render={({ field, fieldState }) => (
                <InputNumber
                  id={field.name}
                  value={field.value}
                  readOnly={true}
                  style={{ width: "50%" }}
                  className="p-inputnumber"
                  mode="decimal"
                  minFractionDigits={2}
                  onChange={(e) => onFieldValueChange(field.name, props, e)}
                />
              )}
            />
          </div>
          <div className="p-inputgroup">
            <span
              className="p-inputgroup-addon p-inputgroup_custom"
              style={{ width: "80%", fontWeight: "bold" }}
            >
              Total Request
            </span>
            <Controller
              name="amt"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  id={field.name}
                  value={field.value}
                  disabled={true}
                  readOnly={true}
                  style={{ width: "50%" }}
                  className="p-inputnumber"
                  mode="decimal"
                  minFractionDigits={2}
                  onChange={(e) => onFieldValueChange(field.name, props, e)}
                />
              )}
            />
          </div>

          <div className="p-inputgroup">
            <span
              className="p-inputgroup-addon p-inputgroup_custom"
              style={{ width: "80%", fontWeight: "bold" }}
            >
              A&P %
            </span>
            <Controller
              name="prjROI"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  id={field.name}
                  value={field.value}
                  disabled={true}
                  suffix=" %"
                  style={{ width: "50%" }}
                  className="p-inputnumber"
                  mode="decimal"
                  minFractionDigits={2}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const confirmDeleteSelected = (rowData, tableName) => {
    // console.log('delete row', rowData, ' ', tableName)
    setActiveTable({ tableName: tableName, rowData: rowData });

    if (tableName === "filelist") {
      setShowDelFileDialog(true);
    } else {
      setShowDelProductDialog(true);
    }
  };

  const addProduct = (props) => {
    let data = getValues(props);
    let idx = data.length + 10;
    data = data.concat({ id: idx, seq: 99 });
    setValue(props, data);
  };

  const reasonExpenses = (data) => {
    setShowReasonDialog(false);

    setValue("action", recAction);
    setValue("recActionReason", recActionReason);

    handleSubmit(onSubmit)();
  };

  const delProduct = () => {
    // alert("delete from " + activeTable.)
    let tblData = getValues(activeTable.tableName);
    // console.log('delete row', activeTable.rowData)
    // console.log('delete', tblData)

    let newTable = tblData.filter((item) => item.id !== activeTable.rowData.id);

    // console.log('new data', newTable)

    setValue(activeTable.tableName, newTable);
    if (activeTable.tableName === "spend") calcRowTotal();

    setShowDelProductDialog(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 1500,
    });
  };

  const fileDownload = (rowData) => {
    var id = getValues("id");
    // alert('Downloading ' + id + '/' + rowData.name)
    // console.log('file_download ', rowData)
    // { query: "option=list", path: id }

    dbGetFileDownload(
      { query: "option=rec&filename=" + rowData.name, path: id },
      rowData.name
    )
      .then((result) => {
        // console.log('dbGetFileDownload', result, result.status)
        // window.open('/download')
        // if (result.status === 201) {
        //     // data.filelist = result.data;
        //     // data.filelist_Ori = result.data;
        //     // console.log("File Lists Details", result)
        //     // console.log("File Lists data", data)
        // }
      })
      .catch((rejected) => {
        console.log("File List Rejected : ", rejected);
      });
  };

  const fileDelete = () => {
    // alert("delete from " + activeTable.)
    let tblData = getValues(activeTable.tableName);
    // console.log('delete activeTable', activeTable.tableName)
    // console.log('delete file', activeTable.rowData)
    // console.log('delete', tblData)

    let newTable = tblData.filter(
      (item) => item.name !== activeTable.rowData.name
    );

    // console.log('new data', newTable)

    setValue(activeTable.tableName, newTable);

    setShowDelFileDialog(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Attached file Deleted",
      life: 1500,
    });
  };

  // const rejectRequest = () => {
  //     setShowReasonDialog(false);
  //     handleSubmit(onSubmit) ;
  //     toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Expenses Rejected', life: 1500 });
  // }

  const actionBodyTemplate = (rowData, tableName) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteSelected(rowData, tableName)}
          tooltip="Delete"
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplateFile = (rowData, tableName) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteSelected(rowData, tableName)}
          tooltip="Delete"
        />
        <Button
          type="button"
          icon="pi pi-download"
          className="p-button-rounded "
          onClick={() => fileDownload(rowData, tableName)}
          tooltip="Download"
        />
      </React.Fragment>
    );
  };
  const actionBodyTemplateFileReadOnly = (rowData, tableName) => {
    return (
      <React.Fragment>
        {/* <Button type="button" icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteSelected(rowData, tableName)} /> */}
        <Button
          type="button"
          icon="pi pi-download"
          className="p-button-rounded "
          onClick={() => fileDownload(rowData, tableName)}
          tooltip="Download"
        />
      </React.Fragment>
    );
  };

  const deleteFileDialogFooter = () => {
    return (
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => {
            setShowDelFileDialog(false);
          }}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={fileDelete}
        />
      </React.Fragment>
    );
  };

  const deleteProductsDialogFooter = () => {
    return (
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => {
            setShowDelProductDialog(false);
          }}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={delProduct}
        />
      </React.Fragment>
    );
  };

  const reasonDialogFooter = () => {
    return (
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => {
            setShowReasonDialog(false);
          }}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={() => {
            reasonExpenses();
          }}
        />
      </React.Fragment>
    );
  };

  const dbGetSpendAll = async (id) => {
    let data = {};
    data.dte = new Date();
    data.dteFrom = new Date();
    data.dteTo = new Date();
    data.dtePay = new Date();
    data.spend = [];
    data.appr = [];
    data.jv = [];
    data.cn = [];
    data.filelist = [];
    data.filelist_Ori = [];

    if (id === 0) {
      setUiSave("1");
      setUiSubmit("1");
    }

    reset(data);

    await DBGetAnp({ query: "option=hdr&type=id", path: id })
      .then((result) => {
        // console.log("Spend Header", result)
        data = result.data[0];
        data.dte = new Date(data.dte);
        data.dteFrom = new Date(data.dteFrom);
        data.dteTo = new Date(data.dteTo);
        data.dtePay = new Date(data.dtePay);
        data.cst = { value: data.cst, label: data.cstNme };
        data.spend = [];
        data.appr = [];
        data.jv = [];
        data.cn = [];
        data.filelist = [];
        data.filelist_Ori = [];

        let ui = data.uiControl.split("");
        setUiCopy(ui[0]);
        setUiEdit(ui[1]);
        setUiSave(ui[2]);
        setUiSubmit(ui[3]);
        setUiApprove(ui[4]);
        setUiReject(ui[5]);
        setUiClose(ui[6]);
        setUiCancel(ui[7]);
        setUiCancelUndo(ui[8]);

        // console.log(uiEdit);

        // console.log("uiControl", data.uiControl);
        // console.log("ui", ui);

        setRec({
          id: data.id,
          recStatus: data.recStatus,
          readOnly:
            data.recStatus === "N" || data.recStatus === "R" ? false : true,
          adminEdit: false,
          showFinanceAppr: data.recStatus !== "N" ? true : false,
          showFinanceDiv:
            data.recStatus === "A" ||
            data.recStatus === "C" ||
            data.recStatus === "MC" ||
            data.recStatus === "X"
              ? true
              : false,
        });
      })
      .catch((rejected) => {
        console.log("Rejected : ", rejected);
      });

    if (id !== 0) {
      await DBGetAnp({ query: "option=dtl&type=id", path: id })
        .then((result) => {
          if (result.status === 201) {
            data.spend = result.data;
            // console.log("Spend Details", result)
          }
        })
        .catch((rejected) => {
          // console.log( "data.spend", data.spend) ;
          console.log("Spend Details Rejected : ", rejected);
        });

      await DBGetAnp({
        query: "option=budget&type=dtl&filter=exptyp='" + data.expTyp + "'",
        path: id,
      })
        .then((result) => {
          if (result.status === 201) {
            data.budget = result.data;
            // console.log("Budget Details", result)
            setBudget({
              opn: result.data[0].amtBud,
              all: result.data[0].amtAll,
              req: data.amt,
              avl: result.data[0].amtBud - result.data[0].amtAll,
              bal: result.data[0].amtBud - result.data[0].amtAll - data.amt,
            });
          }
        })
        .catch((rejected) => {
          console.log("Budget Rejected : ", rejected);
        });
      await DBGetAnp({
        query: "option=budget&type=dtlCom&filter=exptyp='" + data.expTyp + "'",
        path: id,
      })
        .then((result) => {
          if (result.status === 201) {
            data.budgetCom = result.data;
            // console.log("Budget Details", result)
            setBudgetCom({
              opn: result.data[0].amtBud,
              all: result.data[0].amtAll,
              req: data.amt,
              avl: result.data[0].amtBud - result.data[0].amtAll,
              bal: result.data[0].amtBud - result.data[0].amtAll - data.amt,
            });
          }
        })
        .catch((rejected) => {
          console.log("Budget Rejected : ", rejected);
        });
      await DBGetAnp({ query: "option=appr&type=id", path: id })
        .then((result) => {
          if (result.status === 201) {
            data.appr = result.data;
            //   console.log("Approver Details", result);

            result.data.forEach((appr) => {
              if (profile.userID === appr.apprID) {
                console.log("is approver", profile.userID, "=", result.data);
                setIsApprover(true);
              }
            });
          }
        })
        .catch((rejected) => {
          console.log("Approver Rejected : ", rejected);
        });
      await DBGetAnp({ query: "option=jv&type=id", path: id })
        .then((result) => {
          if (result.status === 201) {
            data.jv = result.data;
            console.log("JV Details", result);
          }
        })
        .catch((rejected) => {
          console.log("JV Rejected : ", rejected);
        });
      await DBGetAnp({ query: "option=cn&type=id", path: id })
        .then((result) => {
          if (result.status === 201) {
            data.cn = result.data;
            // console.log("CN Details", result)
          }
        })
        .catch((rejected) => {
          console.log("CN Rejected : ", rejected);
        });

      await dbGetFileList({ query: "option=list", path: id })
        .then((result) => {
          // console.log('dnGetFilelst', result, result.status)
          if (result.status === 201) {
            data.filelist = result.data;
            data.filelist_Ori = result.data;
            // console.log("File Lists Details", result)
            // console.log("File Lists data", data)
          }
        })
        .catch((rejected) => {
          console.log("Filelist Rejected : ", rejected);
        });
    }

    // console.log("data ", data)
    reset(data);
  };

  const recStatusBodyTemplate = (rowData, field) => {
    return refGetRecStatusButton(rowData[`${field}`]);
  };

  /* upload attachment */
  const onUpload = () => {
    // toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
  };

  const chooseOptions = { label: "Add", icon: "pi pi-fw pi-plus" };

  const fileUploadHeaderTemplate = (options) => {
    const { className, chooseButton } = options;
    // const value = totalSize/10000;
    // const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      // <div style={{display: 'none'}}>
      // <div className={className} style={{display: 'none', backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
      <div
        className={className}
        style={{
          display: "none",
          backgroundColor: "transparent",
          alignItems: "center",
        }}
      >
        {chooseButton}
        Max 5 MB
        {/* {uploadButton} */}
        {/* {cancelButton} */}
        {/* <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{width: '300px', height: '20px', marginLeft: 'auto'}}></ProgressBar> */}
      </div>
    );
  };

  const fileUploadFooterTemplate = () => {
    return (
      <FileUpload
        name="files"
        url={Constants.WSURL + "/files/upload/" + recID}
        ref={fileuploader}
        headerTemplate={fileUploadHeaderTemplate}
        chooseOptions={chooseOptions}
        // uploadHandler={myUploader}
        onUpload={onUpload}
        multiple
        id={recID}
        accept="image/*"
        maxFileSize={5000000}
        itemTemplate={fileUploadTemplate}
        emptyTemplate={
          <p className="m-0">Drag and drop files to here to upload.</p>
        }
      />
    );
  };

  const fileUploaOnTemplateRemove = (file, callback) => {
    // setTotalSize(totalSize - file.size);
    callback();
  };

  const fileUploadTemplate = (file, props) => {
    return (
      <div className="p-grid">
        <Button
          type="button"
          style={{ width: "2.357rem" }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => fileUploaOnTemplateRemove(file, props.onRemove)}
        />
        <div class="p-col-5 p-left">{file.name}</div>
        <div class="p-col-2">{Math.round(file.size / 1024)} kb</div>
        <div class="p-col-4 p-left">
          {file.lastModifiedDate.toDateString()}{" "}
          {file.lastModifiedDate.toLocaleTimeString()}
        </div>
        {/* <div class="p-col-2 p-left">
                </div> */}
      </div>
    );
  };

  useEffect(() => {
    setProfile({ ...profile, activeMenuName: "A&P Request" });

    const search = props.location.search;
    let id = new URLSearchParams(search).get("id");
    if (id === null) id = 0;

    dbGetTypList({ query: "type=list&filter=recStatus = 'A'" }, (result) => {
      if (result.status === 201) {
        setSpendType(result.data);
      } else {
        console.log("result.status", result.status);
      }
    });

    dbGetPDList({ query: "type=list" }, (result) => {
      if (result.status === 201) {
        setDistributors(result.data);
      }
    });
    dbGetItmList({ query: "type=list&filter=recStatus = 'A'" }, (result) => {
      if (result.status === 201) {
        setItem(result.data);
        // console.log("dbGetItemList", result.data )
      }
    });
    dbGetSpendAll(id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>
                  A&P Request
                  <span className="layout-h1">
                    {" "}
                    [anp-{rec.id} ] {getRecStatus(rec.recStatus)}{" "}
                  </span>
                  <div className="p-actionbuttonset">
                    {uiEdit === "1" &&
                    authUsrRole.indexOf(profile.usrRole) !== -1 ? (
                      <Button
                        type="button"
                        icon="pi pi-key"
                        className="p-button-rounded p-button-warning"
                        onClick={() => onActionButtonClick("Edit")}
                        tooltip="Edit"
                      />
                    ) : null}
                    {uiCopy === "1" ? (
                      <Button
                        type="button"
                        icon="pi pi-copy"
                        className="p-button-rounded"
                        onClick={() => onActionButtonClick("Copy")}
                        tooltip="Copy"
                      />
                    ) : null}

                    {uiSave === "1" ? (
                      <Button
                        type="submit"
                        icon="pi pi-save"
                        className="p-button-rounded"
                        onClick={() => onActionButtonClick("Save")}
                        tooltip="Save"
                      />
                    ) : null}

                    {uiSubmit === "1" ? (
                      <Button
                        type="submit"
                        icon="pi pi-envelope"
                        className="p-button-rounded p-button-success"
                        onClick={() => onActionButtonClick("Submit")}
                        tooltip="Submit"
                      />
                    ) : null}
                    {/* {uiCancel.indexOf(rec.recStatus) !== -1 ? (
                      <Button
                        type="submit"
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning"
                        onClick={() => onActionButtonClick("Delete")}
                        tooltip="Delete"
                      />
                    ) : null} */}
                    {uiApprove === "1" && isApprover ? (
                      <Button
                        type="submit"
                        icon="pi pi-check"
                        className="p-button-rounded p-button-success"
                        onClick={() => onActionButtonClick("Approve")}
                        tooltip="Approve"
                      />
                    ) : null}
                    {uiReject === "1" && isApprover ? (
                      <Button
                        type="button"
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger"
                        onClick={() => onActionButtonClick("Reject")}
                        tooltip="Reject"
                      />
                    ) : null}
                    {uiCancel === "1" &&
                    authUsrRole.indexOf(profile.usrRole) !== -1 ? (
                      <Button
                        type="button"
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger"
                        onClick={() => onActionButtonClick("Cancel")}
                        tooltip="Cancel"
                      />
                    ) : null}
                    {uiCancelUndo === "1" ? (
                      <Button
                        type="button"
                        icon="pi pi-replay"
                        className="p-button-rounded p-button-danger"
                        onClick={() => onActionButtonClick("Cancel_Undo")}
                        tooltip="Undo cancel"
                      />
                    ) : null}
                    {uiClose === "1" &&
                    authUsrRole.indexOf(profile.usrRole) !== -1 ? (
                      <Button
                        type="button"
                        icon="pi pi-lock"
                        className="p-button-rounded p-button-warning"
                        onClick={() => onActionButtonClick("Close")}
                        tooltip="Close"
                      />
                    ) : null}
                    <Button
                      type="button"
                      icon="pi pi-step-backward"
                      className="p-button-rounded"
                      onClick={() => onActionButtonClick("PageBack")}
                      tooltip="Back"
                    />
                  </div>
                </h1>
                <div className="p-grid">
                  <div className="p-md-6 p-sm-12">
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="pd"
                          control={control}
                          rules={{ required: "Distributor is required." }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.name}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              options={pdOptions}
                              optionLabel="label"
                              autoFocus
                              disabled={rec.readOnly}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        {getFormErrorMessage("pd")}
                        <label htmlFor="pd">Distributor</label>
                      </span>
                    </div>
                  </div>
                  <div className="p-md-6 p-sm-12">
                    <div className="p-grid">
                      <div className="p-md-3 p-sm-12">
                        <div className="p-field div-calender">
                          <span className="p-float-label">
                            <Controller
                              name="dte"
                              control={control}
                              rules={{ required: "inputtext is required." }}
                              render={({ field, fieldState }) => (
                                <Calendar
                                  id={field.name}
                                  value={field.value}
                                  onChange={(e) => field.onChange(e.value)}
                                  dateFormat="dd-M-yy"
                                  mask="99/99/9999"
                                  showIcon
                                  showButtonBar
                                  disabled={rec.readOnly}
                                  className={classnames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                />
                              )}
                            />
                            <label htmlFor="dte">Request Date</label>
                            {getFormErrorMessage("dte")}
                          </span>
                        </div>
                      </div>
                      <div className="p-md-3 p-sm-12">
                        <div className="p-field div-calender">
                          <span className="p-float-label">
                            <Controller
                              name="dteFrom"
                              control={control}
                              rules={{ required: "inputtext is required." }}
                              render={({ field, fieldState }) => (
                                <Calendar
                                  id={field.name}
                                  disabled={rec.readOnly}
                                  value={field.value}
                                  onChange={(e) => field.onChange(e.value)}
                                  dateFormat="dd-M-yy"
                                  mask="99/99/9999"
                                  showIcon
                                  showButtonBar
                                  className={classnames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                />
                              )}
                            />
                            <label htmlFor="dteFrom">Promotion From Date</label>
                            {getFormErrorMessage("date")}
                          </span>
                        </div>
                      </div>
                      <div className="p-md-3 p-sm-12">
                        <div className="p-field div-calender">
                          <span className="p-float-label">
                            <Controller
                              name="dteTo"
                              control={control}
                              rules={{ required: "inputtext is required." }}
                              render={({ field, fieldState }) => (
                                <Calendar
                                  id={field.name}
                                  disabled={rec.readOnly}
                                  value={field.value}
                                  onChange={(e) => field.onChange(e.value)}
                                  dateFormat="dd-M-yy"
                                  mask="99/99/9999"
                                  showIcon
                                  showButtonBar
                                  className={classnames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                />
                              )}
                            />
                            <label htmlFor="dteTo">To Date</label>
                            {getFormErrorMessage("date")}
                          </span>
                        </div>
                      </div>
                      <div className="p-md-3 p-sm-12">
                        <div className="p-field div-calender">
                          <span className="p-float-label">
                            <Controller
                              name="dtePay"
                              control={control}
                              render={({ field, fieldState }) => (
                                <Calendar
                                  id={field.name}
                                  disabled={true}
                                  value={field.value}
                                  onChange={(e) => field.onChange(e.value)}
                                  dateFormat="dd-M-yy"
                                  mask="99/99/9999"
                                  showIcon
                                  showButtonBar
                                  className={classnames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                />
                              )}
                            />
                            <label htmlFor="dtePay">Payment Date</label>
                            {getFormErrorMessage("date")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-md-6 p-sm-12">
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="cst"
                          control={control}
                          rules={{ required: "Customer is required." }}
                          render={({ field, fieldState }) => (
                            <AutoComplete
                              id={field.name}
                              minLength={1}
                              {...field}
                              field="label"
                              disabled={rec.readOnly}
                              completeMethod={cstSearch}
                              suggestions={cstFiltered}
                              onChange={(e) => field.onChange(e.value)}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="cst"
                          className={classnames({ "p-error": errors.name })}
                        >
                          Customer*
                        </label>
                      </span>
                      {getFormErrorMessage("cst")}
                    </div>
                    `
                  </div>
                  <div className="p-md-6 p-sm-12">
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="expTyp"
                          control={control}
                          rules={{ required: "Expense Type is required." }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.name}
                              value={field.value}
                              disabled={rec.readOnly}
                              onChange={(e) => field.onChange(e.value)}
                              options={typOptions}
                              optionLabel="label"
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        {getFormErrorMessage("pd")}
                        <label htmlFor="typ">Expense Type</label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Product, Attachment, Budget Card  */}
        <div className="p-md-12 p-sm-12">
          <div className="card card-w-title">
            <h1>Request Details</h1>
            <div className="p-grid">
              <div className="p-md-6 p-sm-12">
                <Controller
                  name="spend"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DataTable
                      disabled={rec.readOnly}
                      value={field.value}
                      selectionMode="single"
                      // header={headerTemplate}
                      footer={footerTemplate}
                    >
                      <Column
                        field="itm"
                        header="Product"
                        sortable={true}
                        style={{ width: "50%" }}
                        editor={(props) =>
                          dropdownEditor("product", props, field)
                        }
                      />
                      <Column
                        field="amt"
                        header="Amount"
                        sortable={true}
                        style={{ width: "15%", textAlign: "right" }}
                        className="columnRight"
                        readonly={rec.readOnly}
                        editor={(props) => priceEditor("amt", props)}
                        body={(rowData) => priceBodyTemplate(rowData, "amt")}
                      />
                      <Column
                        field="prjSls"
                        header="Prj Sales"
                        sortable={true}
                        style={{ width: "15%", textAlign: "right" }}
                        className="columnRight"
                        readonly={rec.readOnly}
                        editor={(props) => priceEditor("prjSls", props)}
                        body={(rowData) => priceBodyTemplate(rowData, "prjSls")}
                      />
                      <Column
                        field="prjROI"
                        header="A&P %"
                        sortable={true}
                        style={{ width: "15%", textAlign: "right" }}
                        className="columnRight"
                        readonly={true}
                        // editor={(props) => priceEditor('prjROI', props)}
                        body={(rowData) =>
                          percentageBodyTemplate(rowData, "prjROI")
                        }
                      />
                      <Column
                        field="Seq"
                        header="Seq"
                        style={{ width: "15%", textAlign: "right" }}
                        className="columnRight"
                        readonly={true}
                        hidden={true}
                        // editor={(props) => priceEditor('prjROI', props)}
                        body={(rowData) =>
                          percentageBodyTemplate(rowData, "seq")
                        }
                      />
                      {rec.readOnly ? null : (
                        <Column
                          header={header_prd}
                          body={(rowData) =>
                            actionBodyTemplate(rowData, "spend")
                          }
                        ></Column>
                      )}
                    </DataTable>
                  )}
                />
                {/* <Controller name="prjSls" control={control} rules={{ required: 'Projected Sales is required.' }} render={({ field, fieldState }) => (
                                        <InputNumber id={field.name} 
                                            value={field.value}
                                            // disabled={rec.readOnly}
                                            style={{ width: '50%' }}
                                            className="p-inputnumber"
                                            mode="decimal" minFractionDigits={2}
                                            // onChange={(e) => field.onChange(e.value)}
                                            onChange={(e) => onFieldValueChange(field.name, props, e)}
                                         />
                                    )} /> */}
                {/* <Controller name="roi" control={control} render={({ field, fieldState }) => (
                                        <InputNumber id={field.name} 
                                            value = {field.value}
                                            disabled = {true}
                                            style={{ width: '50%' }}
                                            className="p-inputnumber"
                                            mode="decimal" minFractionDigits={2}
                                         />
                                    )} /> */}
                <br></br>
                <h2>Attachments</h2>
                <br></br>
                <div>
                  <Controller
                    name="filelist"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DataTable
                        footer={fileUploadFooterTemplate}
                        value={field.value}
                        selectionMode="single"
                      >
                        {/* <Column field="id" header="No." sortable={true} style={{ width: '5%' }} /> */}
                        <Column
                          field="name"
                          header="File Name"
                          sortable={true}
                          style={{ width: "40%" }}
                        />
                        <Column
                          field="size"
                          header="Size"
                          body={(rowData) =>
                            fileSizeBodyTemplate(rowData, "size")
                          }
                          sortable={true}
                          style={{ width: "15%" }}
                        />
                        <Column
                          field="modifiedOn"
                          header="Date"
                          sortable={true}
                          style={{ width: "18%" }}
                          body={(rowData) =>
                            datetimeBodyTemplate(rowData, "modifiedOn")
                          }
                        />

                        {rec.readOnly ? (
                          <Column
                            style={{ width: "15%" }}
                            body={(rowData) =>
                              actionBodyTemplateFileReadOnly(
                                rowData,
                                "filelist"
                              )
                            }
                          ></Column>
                        ) : (
                          <Column
                            style={{ width: "15%" }}
                            body={(rowData) =>
                              actionBodyTemplateFile(rowData, "filelist")
                            }
                          ></Column>
                        )}
                      </DataTable>
                    )}
                  />
                </div>
              </div>
              {/* Budget Box */}
              <div className="p-md-6 p-sm-12">
                {rec.recStatus === "AP" ? (
                  <div
                    className="p-md-12 p-sm-12"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="p-grid">
                      {/* Personal Budget */}
                      <div
                        className="p-md-6 p-sm-12"
                        style={{ marginBottom: "20px" }}
                      >
                        <div className="p-inputgroup">
                          <span
                            className="p-inputgroup-addon p-inputgroup_custom"
                            style={{ width: "100%" }}
                          >
                            In Charged
                          </span>
                        </div>
                        <div className="p-inputgroup">
                          <span
                            className="p-inputgroup-addon p-inputgroup_custom"
                            style={{ width: 100 }}
                          >
                            Budget
                          </span>
                          <InputNumber
                            value={budget.opn}
                            style={{ width: 150 }}
                            className="p-inputnumber"
                            readOnly
                            mode="decimal"
                            minFractionDigits={2}
                          />
                          {/* <span className="p-inputgroup-addon">.00</span> */}
                        </div>
                        {/* <div className="p-md-6 p-sm-12" style={{ marginBottom: '20px' }}> */}
                        <div className="p-inputgroup">
                          <span
                            className="p-inputgroup-addon p-inputgroup_custom"
                            style={{ width: 100 }}
                          >
                            Allocated
                          </span>
                          <InputNumber
                            value={budget.all}
                            style={{ width: 150 }}
                            className="p-inputnumber"
                            readOnly
                            mode="decimal"
                            minFractionDigits={2}
                          />
                          {/* <span className="p-inputgroup-addon">.00</span> */}
                        </div>
                        <div className="p-inputgroup">
                          <span
                            className="p-inputgroup-addon p-inputgroup_custom"
                            style={{ width: 100 }}
                          >
                            Available
                          </span>
                          <InputNumber
                            value={budget.avl}
                            style={{ width: 150 }}
                            className="p-inputnumber"
                            readOnly
                            mode="decimal"
                            minFractionDigits={2}
                          />
                          {/* <span className="p-inputgroup-addon">.00</span> */}
                        </div>
                        <div className="p-inputgroup">
                          <span
                            className="p-inputgroup-addon p-inputgroup_custom"
                            style={{ width: 100 }}
                          >
                            Request
                          </span>
                          <InputNumber
                            value={budget.req}
                            style={{ width: 150 }}
                            className="p-inputnumber"
                            mode="decimal"
                            minFractionDigits={2}
                            readOnly
                          />
                          {/* <span className="p-inputgroup-addon">.00</span> */}
                        </div>
                        <div className="p-inputgroup">
                          <span
                            className="p-inputgroup-addon p-inputgroup_custom"
                            style={{ width: 100 }}
                          >
                            Balance
                          </span>
                          <InputNumber
                            value={budget.bal}
                            style={{ width: 150 }}
                            className="p-inputnumber"
                            mode="decimal"
                            minFractionDigits={2}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* Company Budget */}
                      {profile.viewBudgetCom === 1 ? (
                        <div
                          className="p-md-6 p-sm-12"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="p-inputgroup">
                            <span
                              className="p-inputgroup-addon p-inputgroup_custom"
                              style={{ width: "100%" }}
                            >
                              Company
                            </span>
                          </div>
                          <div className="p-inputgroup">
                            <span
                              className="p-inputgroup-addon p-inputgroup_custom"
                              style={{ width: 100 }}
                            >
                              Budget
                            </span>
                            <InputNumber
                              value={budgetCom.opn}
                              style={{ width: 150 }}
                              className="p-inputnumber"
                              readOnly
                              mode="decimal"
                              minFractionDigits={2}
                            />
                            {/* <span className="p-inputgroup-addon">.00</span> */}
                          </div>
                          <div className="p-inputgroup">
                            <span
                              className="p-inputgroup-addon p-inputgroup_custom"
                              style={{ width: 100 }}
                            >
                              Allocated
                            </span>
                            <InputNumber
                              value={budgetCom.all}
                              style={{ width: 150 }}
                              className="p-inputnumber"
                              readOnly
                              mode="decimal"
                              minFractionDigits={2}
                            />
                            {/* <span className="p-inputgroup-addon">.00</span> */}
                          </div>
                          <div className="p-inputgroup">
                            <span
                              className="p-inputgroup-addon p-inputgroup_custom"
                              style={{ width: 100 }}
                            >
                              Available
                            </span>
                            <InputNumber
                              value={budgetCom.avl}
                              style={{ width: 150 }}
                              className="p-inputnumber"
                              readOnly
                              mode="decimal"
                              minFractionDigits={2}
                            />
                            {/* <span className="p-inputgroup-addon">.00</span> */}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div></div>
                <div className="p-grid">
                  <div className="p-md-3 p-sm-12">
                    <div className="p-field div-remark">
                      <span className="p-float-label">
                        <Controller
                          name="rmk"
                          control={control}
                          rules={{ required: "Remarks is required." }}
                          render={({ field, fieldState }) => (
                            <InputTextarea
                              id={field.name}
                              {...field}
                              rows={9}
                              cols={1}
                              autoResize="true"
                              disabled={rec.readOnly}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="rmk"
                          className={classnames({ "p-error": errors.name })}
                        >
                          Remarks*
                        </label>
                      </span>
                      {getFormErrorMessage("rmk")}
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>

          {/* Approval Card  */}
          {rec.showFinanceAppr ? (
            <div className="p-md-12 p-sm-12">
              <div className="card card-w-title">
                <h1>Requester / Approver</h1>
                <Controller
                  name="appr"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DataTable value={field.value} selectionMode="single">
                      <Column
                        field="id"
                        header="No."
                        sortable={true}
                        style={{ width: "5%" }}
                      />
                      <Column
                        field="apprID"
                        header="user id"
                        sortable={true}
                        style={{ width: "15%" }}
                      />
                      <Column
                        field="usrNme"
                        header="Name"
                        sortable={true}
                        style={{ width: "15%" }}
                      />
                      <Column
                        field="apprDte"
                        header="Date"
                        sortable={true}
                        style={{ width: "15%" }}
                        body={(rowData) =>
                          datetimeBodyTemplate(rowData, "apprDte")
                        }
                      />
                      <Column
                        field="recStatus"
                        sortable={true}
                        style={{ width: "5%" }}
                        body={(rowData) =>
                          recStatusBodyTemplate(rowData, "recStatus")
                        }
                      />
                      <Column
                        field="rmk"
                        header="Remarks"
                        class="cw_wrapText"
                        sortable={true}
                        style={{ width: "40%" }}
                      />
                    </DataTable>
                  )}
                />
              </div>
            </div>
          ) : null}

          {rec.showFinanceDiv ? (
            <div className="p-md-12 p-sm-12">
              <div className="card card-w-title">
                <h1>Finance : Provisions / Reversal</h1>
                <Controller
                  name="jv"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DataTable value={field.value} selectionMode="single">
                      <Column
                        field="id"
                        header="No."
                        sortable={false}
                        style={{ width: "8%" }}
                      />
                      <Column
                        field="jvDte"
                        header="Date"
                        sortable={false}
                        style={{ width: "10%" }}
                        body={(rowData) => dateBodyTemplate(rowData, "jvDte")}
                      />
                      <Column
                        field="jvNo"
                        header="JV No"
                        sortable={false}
                        style={{ width: "8%" }}
                      />
                      <Column
                        field="jvLog"
                        header="Remark"
                        sortable={false}
                        style={{ width: "60%" }}
                      />
                      <Column
                        field="jvBal"
                        header="Amount"
                        sortable={false}
                        style={{ width: "10%" }}
                        className="columnRight"
                        editor={(props) => priceEditor("jvBal", props)}
                        body={(rowData) => priceBodyTemplate(rowData, "jvBal")}
                      />
                      <Column
                        field="jvStatus"
                        // filterMenuStyle={{ width: '14rem' }}
                        filter={false}
                        className="columnCenter"
                        // filterElement={statusFilterTemplate}
                        sortable={false}
                        style={{ width: "10px" }}
                        body={(rowData) =>
                          recStatusBodyTemplate(rowData, "jvStatus")
                        }
                      />
                      {/* <Column header={header_jv} body={(rowData) => actionBodyTemplate(rowData, 'jv')} style={{ width: '10%', textAlign: 'right' }}></Column> */}
                    </DataTable>
                  )}
                />
              </div>
            </div>
          ) : null}

          {rec.showFinanceDiv ? (
            <div className="p-md-12 p-sm-12">
              <div className="card card-w-title">
                <h1>Finance : Claims (DN/CN)</h1>
                <Controller
                  name="cn"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DataTable value={field.value} selectionMode="single">
                      <Column
                        field="id"
                        header="No."
                        sortable={true}
                        style={{ width: "5%" }}
                      />
                      <Column
                        field="dnDte"
                        header="DN Date"
                        sortable={true}
                        style={{ width: "15%" }}
                        dateFormat="dd-M-yy"
                        mask="99/99/9999"
                        showIcon
                        showButtonBar
                        editor={(props) => dateEditor("dnDte", props)}
                        body={(rowData) => dateBodyTemplate(rowData, "dnDte")}
                      />
                      <Column
                        field="dnTyp"
                        header="CN/DN"
                        sortable={true}
                        style={{ width: "5%" }}
                      />
                      <Column
                        field="dnNo"
                        header="Claim CN/DN"
                        sortable={true}
                        style={{ width: "25%" }}
                      />
                      <Column
                        field="dnAmt"
                        header="DR Amount"
                        sortable={true}
                        className="columnRight"
                        style={{ width: "10%", textAlign: "right" }}
                        body={(rowData) => priceBodyTemplate(rowData, "dnAmt")}
                      />
                      <Column
                        field="cnAmt"
                        header="CR Amount"
                        sortable={true}
                        className="columnRight"
                        style={{ width: "10%", textAlign: "right" }}
                        body={(rowData) => priceBodyTemplate(rowData, "cnAmt")}
                      />
                      <Column
                        field="cnBal"
                        header="Balance"
                        sortable={true}
                        className="columnRight"
                        style={{ width: "10%", textAlign: "right" }}
                        body={(rowData) => priceBodyTemplate(rowData, "dnBal")}
                      />
                      {/* <Column header={header_cn} body={(rowData) => actionBodyTemplate(rowData, 'dnBal')} 
                                            className="center" style={{ width: '10%',  textAlign: 'right' }}></Column> */}
                    </DataTable>
                  )}
                />
              </div>
            </div>
          ) : null}
        </div>
      </form>

      <Dialog
        visible={showReasonDialog}
        style={{ width: "450px" }}
        // header=  "Reject this expenses?"
        header={() => {
          switch (recAction) {
            case "Reject":
              return "Reject this ANP expense?";
            case "Close":
              return "Close this expense?";
            case "Open":
              return "Re-open this expense?";
            case "Cancel":
              return "Cancel this expense?";
            case "Cancel_Undo":
              return "Undo Cancellation this expense? Reset to new ANP";
            default:
              return recAction;
          }
        }}
        modal
        footer={reasonDialogFooter}
        onHide={() => setShowReasonDialog(false)}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <div className="p-md-2 p-sm-12">
            <label htmlFor="reason">Remark</label>
          </div>
          <InputTextarea
            id={recActionReason}
            rows={9}
            cols={50}
            autoResize="true"
            onChange={(e) => setrecActionReason(e.target.value)}
          />
        </div>
      </Dialog>

      <Dialog
        visible={showDelProductDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        // onHide={showDelProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete?</span>}
        </div>
      </Dialog>

      <Dialog
        visible={showDelFileDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteFileDialogFooter}
        // onHide={showDelProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete this attachement?</span>}
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
}
