import React, { useContext } from 'react';
import { dbUserSignOff } from './services/UserService'
// import classNames from 'classnames';

import { LoginContext } from './contexts/LoginContext';

// import PropTypes from 'prop-types';

export default function AppTopbar(props) {
    const { setAuth, setProfile, profile } = useContext(LoginContext);

    const onClick = () => {
        // alert("Logout");
        setAuth(false)
        setProfile({})
        dbUserSignOff();
    };

    return (
        <div className="layout-topbar clearfix">
            <button className="p-link layout-menu-button" onClick={props.onToggleMenu}>
                <span className="pi pi-bars" />
            </button>
            <span className="layout-topbar-title">{profile.activeMenuName}</span>
            <div className="layout-topbar-icons">
                <button className="p-link" onClick={onClick}>
                    <span className="layout-topbar-item-text">Logout</span>
                    <span className="layout-topbar-icon pi pi-power-off" />
                </button>
            </div>
        </div>
    );
}