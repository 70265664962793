import * as Constants from './dbConnection'
// import React, { useContext } from "react";

// import { LoginContext } from '../contexts/LoginContext';

// import FileSaver from 'file-saver';

export function dbSetFile(data, options) {
    return new Promise((resolve, reject) => {
        // console.log("[DBSetFile]")
        // console.log("- data ", data)
        // console.log("- option", options)

        // const httpQuery = options.query;
        // const httpPath = options.path;
        // var jsonReq = JSON.stringify(data);

        const accessToken = localStorage.getItem('accessToken');
        const httpHeaders = {
            // 'Content-Type': 'application/json',
            'Content-Type' : 'multipart/form-data',
            'Access-Control-Allow-Headers': 'Authorization',
            'Authorization': `Bearer ${accessToken}`
        }

        const requestOptions = {
            method: 'POST',
            headers: httpHeaders,
            body: data
            // body: jsonReq
        };

        fetch(Constants.WSURL + '/files/upload', requestOptions)
            .then(res => res.json())
            .then(result => {
                if (result.status === 403) {
                    alert("Session Expired")
                    // setAuth(false);
                }
                resolve(result);
            })
            .catch(rejected => {
                reject({ err: rejected });
            });
    })
}

export function dbGetFileList(options) {
    return new Promise((resolve, reject) => {
        // const { profile } = useContext(LoginContext);
        // console("DBGetAnp", profile)

        const httpQuery = options.query;
        const httpPath = options.path;

        const accessToken = localStorage.getItem('accessToken');
        const httpHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': 'Authorization',
            'Authorization': `Bearer ${accessToken}`
        }

        const requestOptions = {
            method: 'GET',
            headers: httpHeaders,
        };

        fetch(Constants.WSURL + '/files/list' + ((httpPath === undefined) ? "" : "/" + httpPath) + "?" + httpQuery, requestOptions)
            .then(res => res.json())
            .then(result => {
                if (result.status === 403) {
                    alert("Session Expired")
                    // setAuth(false);
                }

                // console.log("FILE ", result)
                resolve(result);
            })
            .catch(rejected => {
                reject({ err: rejected });
            });
    })
}

export function dbGetFileDownload(options, filename) {
    return new Promise((resolve, reject) => {
        // const { profile } = useContext(LoginContext);
        // console("DBGetAnp", profile)

        const httpQuery = options.query;
        const httpPath = options.path;

        const accessToken = localStorage.getItem('accessToken');
        const httpHeaders = {
            // 'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': 'Authorization',
            'Authorization': `Bearer ${accessToken}`
        }

        const requestOptions = {
            method: 'GET',
            headers: httpHeaders,
        };

        // console.log("filename" , filename)
        fetch(Constants.WSURL + '/files/download' + ((httpPath === undefined) ? "" : "/" + httpPath) + "?" + httpQuery, requestOptions)
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                return Promise.reject({ Error: 'Something Went Wrong', err });
            })
    })
}


