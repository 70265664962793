import React, { Component } from "react";
// import { Toast } from "primereact/toast";

import classNames from "classnames";
import AppTopbar from "../AppTopbar";
import { AppFooter } from "../AppFooter";
import { AppMenu } from "../AppMenu";
import AppProfile from "../AppProfile";
import { Route } from "react-router-dom";
import AnpRequest from "./anpRequest";
import AnpRequestGrid from "./anpRequestGrid";
import AnpRequestGridPending from "./anpRequestGridPending";
import AnpRequestJVGrid from "./anpRequestJVGrid";
import CwUserGrid from "./cwUserGrid";
import CwUser from "./cwUser";

// import EmptyPage from '../components/emptyPage';

import { LoginContext } from "../contexts/LoginContext";

import { Dashboard } from "./dashboard";

import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "../layout/layout.scss";
import "../App.scss";

export class Home extends Component {
  static contextType = LoginContext;

  constructor(props) {
    // console.log("PROPS", props);
    super();
    this.state = {
      title: "Codeworks",
      layoutMode: "static",
      layoutColorMode: "dark",
      // layoutColorMode: 'light',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      userMenu: "11111",
      // profile: {}
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    // this.createMenu();
  }

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  createMenu() {
    // console.log("createmenu", this.context)
    const usrMenu = this.context.profile.userMenu;

    this.menu = [];
    if (usrMenu.substring(0, 1) === "1")
      this.menu.push({
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        command: () => {
          window.location = "#/";
        },
      });
    if (usrMenu.substring(1, 2) === "1")
      this.menu.push({
        label: "Spend Request",
        icon: "pi pi-fw pi-pencil",
        command: () => {
          window.location = "#/anpRequestGrid";
        },
      });
    if (usrMenu.substring(2, 3) === "1")
      this.menu.push({
        label: "My Approval",
        icon: "pi pi-fw pi-pencil",
        command: () => {
          window.location = "#/anpRequestGridPending";
        },
      });
    if (usrMenu.substring(3, 4) === "1")
      this.menu.push({
        label: "Spend JV",
        icon: "pi pi-fw pi-pencil",
        command: () => {
          window.location = "#/anpRequestJVGrid";
        },
      });
    if (usrMenu.substring(4, 5) === "1")
      this.menu.push({
        label: "Users",
        icon: "pi pi-fw pi-users",
        command: () => {
          window.location = "#/cwUserGrid";
        },
      });
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  }

  componentWillUnmount() {
    // console.log("componentWillUnmount profile userMenu ", this.context.profile);
  }

  render() {
    this.createMenu();

    const logo =
      this.state.layoutColorMode === "dark"
        ? "assets/layout/images/logo-white.png"
        : "assets/layout/images/logo-black.png";

    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
    });

    const sidebarClassName = classNames("layout-sidebar", {
      "layout-sidebar-dark": this.state.layoutColorMode === "dark",
      "layout-sidebar-light": this.state.layoutColorMode === "light",
    });

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <AppTopbar onToggleMenu={this.onToggleMenu} />
        <div
          ref={(el) => (this.sidebar = el)}
          className={sidebarClassName}
          onClick={this.onSidebarClick}
        >
          <div className="layout-logo">
            <img alt="Logo" src={logo} />
          </div>
          <AppProfile />
          <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
        </div>
        <div className="layout-main">
          <Route path="/" exact component={Dashboard} />
          <Route path="/anpRequest" component={AnpRequest} />
          <Route path="/anpRequestGrid" component={AnpRequestGrid} />
          <Route
            path="/anpRequestGridPending"
            component={AnpRequestGridPending}
          />
          <Route path="/anpRequestJVGrid" component={AnpRequestJVGrid} />
          <Route path="/cwUserGrid" component={CwUserGrid} />
          <Route path="/cwUser" component={CwUser} />
        </div>

        <AppFooter />
        <div className="layout-mask"></div>

        {/* <Toast ref={AppToast} /> */}
      </div>
    );
  }
}
