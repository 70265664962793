import * as Constants from "./dbConnection";
import { Buffer } from "buffer";

export function dbUserSignOn(param) {
  return new Promise((resolve, reject) => {
    const pHash = Buffer.from(param.userid + ":" + param.password).toString(
      "base64"
    );

    const httpHeaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "Authorization",
      Authorization: `Basic ${pHash}`,
    };

    const requestOptions = {
      method: "POST",
      headers: httpHeaders,
      // mode: 'no-cors',
      // body: JSON.stringify({ user })
    };

    fetch(Constants.WSURL + "/login", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        //  console.log("login data", data);
        if (data.status === 202) {
          localStorage.setItem("accessToken", data.accessToken);
          localStorage.setItem("refreshToken", data.refreshToken);

          // console.log( "login return ", data.user.recID )
          resolve(data.user.recID);
        } else {
          reject({ err: "Login failed : Invalid User ID / Password" });
        }
      })
      .catch((rejected) => {
        // console.log("Rejected ", rejected);
        // console.log("user : ", user)
        reject({ err: rejected });
      });
  });
}

// export async function dbUserSignOn(user, callback) {
//     const pHash = Buffer.from(user.userid + ':' + user.password).toString('base64');

//     //

//     const httpHeaders = {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Headers': 'Authorization',
//         'Authorization': `Basic ${pHash}`,
//     }

//     // console.log(Constants.WSURL + '/login  --> ', httpHeaders);

//     const requestOptions = {
//         method: 'POST',
//         headers: httpHeaders,
//         // mode: 'no-cors',
//         // body: JSON.stringify({ user })
//     }

//     await fetch(Constants.WSURL + '/login', requestOptions)
//         .then(res => res.json())
//         .then(data => {
//             // console.log("login data", data);
//             if (data.status === 202) {
//                 localStorage.setItem('accessToken', data.accessToken);
//                 localStorage.setItem('refreshToken', data.refreshToken);
//                 callback(true);
//             } else {
//                 callback(false);
//             }
//         })
//         .catch(rejected => {
//             // console.log("Rejected ", rejected);
//             // console.log("user : ", user)
//             callback(false);
//         });
// }

export function dbGetUserDetails(options) {
  return new Promise((resolve, reject) => {
    const httpQuery = options.query;
    const httpPath = options.path;

    const accessToken = localStorage.getItem("accessToken");
    const httpHeaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "Authorization",
      Authorization: `Bearer ${accessToken}`,
    };

    const requestOptions = {
      method: "GET",
      headers: httpHeaders,
    };
    fetch(
      Constants.WSURL +
        "/Users" +
        (httpPath === undefined ? "" : "/" + httpPath) +
        (httpQuery === "" ? "" : "?" + httpQuery),
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 403) {
          alert("Session Expired");
          // setAuth(false);
        }
        resolve(result);
      })
      .catch((rejected) => {
        reject({ err: rejected });
      });
  });
}

export function dbGetUser(options) {
  return new Promise((resolve, reject) => {
    const httpQuery = options.query;
    const httpPath = options.path;

    const accessToken = localStorage.getItem("accessToken");
    const httpHeaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "Authorization",
      Authorization: `Bearer ${accessToken}`,
    };

    const requestOptions = {
      method: "GET",
      headers: httpHeaders,
    };

    fetch(
      Constants.WSURL +
        "/Users" +
        (httpPath === undefined ? "" : "/" + httpPath) +
        "?" +
        httpQuery,
      requestOptions
    )
      .then((res) => {
        // console.log("res", res)
        if (res.status === 201) return res.json();
        else return { dataStatus: "OK", status: res.status, data: [] };
      })
      .then((result) => {
        // console.log(" data sec resiult", result) ;

        if (result.status === 403) {
          alert("Session Expired");
          // setAuth(false);
        }
        resolve(result);
      })
      .catch((rejected) => {
        reject({ err: rejected });
      });
  });
}

export async function dbUserSignOff() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  // const pHash = Buffer.from(user.userid + ':' + user.password).toString('base64');

  // //

  // const httpHeaders = {
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Headers': 'Authorization',
  //     'Authorization': `Basic ${pHash}`,
  // }

  // // console.log(Constants.WSURL + '/login  --> ', httpHeaders);

  // const requestOptions = {
  //     method: 'POST',
  //     headers: httpHeaders,
  //     // mode: 'no-cors',
  //     // body: JSON.stringify({ user })
  // }

  // await fetch(Constants.WSURL + '/login', requestOptions)
  //     .then(res => res.json())
  //     .then(data => {
  //         if (data.status === 202) {
  //             localStorage.setItem('accessToken', data.accessToken);
  //             localStorage.setItem('refreshToken', data.refreshToken);
  //             callback(true);
  //         } else {
  //             callback(false);
  //         }
  //     })
  //     .catch(rejected => {
  //         console.log("Rejected ", rejected);
  //         console.log("user : ", user)
  //         callback(false);
  //     });
}
export function dbSetUser(data, callback) {
  const accessToken = localStorage.getItem("accessToken");
  var jsonReq = JSON.stringify(data);
  const httpHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "Authorization",
    Authorization: `Bearer ${accessToken}`,
  };

  console.log("Set User Services", jsonReq);
  const requestOptions = {
    method: "POST",
    headers: httpHeaders,
    body: jsonReq,
  };
  fetch(Constants.WSURL + "/users", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      // console.log("anp get ", result)
      if (result.status === 403) {
        alert("Session Expired");
        // setAuth(false);
      }
      // console.log(" Rsddsds", result)
      callback(result);
    })
    .catch((rejected) => {
      console.log("Rejected");
      callback({ err: rejected });
    });
}
// export function dbSetUser(data, options) {
//     return new Promise((resolve, reject) => {
//         console.log("[DBSetAnp]")
//         console.log("- data ", data)
//         console.log("- option", options)

//         const httpQuery = options.query;
//         const httpPath = options.path;
//         var jsonReq = JSON.stringify(data);

//         // console.log("- jsonReq", jsonReq)

//         const accessToken = localStorage.getItem('accessToken');
//         const httpHeaders = {
//             'Content-Type': 'application/json',
//             'Access-Control-Allow-Headers': 'Authorization',
//             'Authorization': `Bearer ${accessToken}`
//         }

//         const requestOptions = {
//             method: 'PUT',
//             headers: httpHeaders,
//             body: jsonReq
//         };

//         fetch(Constants.WSURL + '/anp' + ((httpPath === undefined) ? "" : "/" + httpPath) + "?" + httpQuery, requestOptions)
//             .then(res => res.json())
//             .then(result => {
//                 if (result.status === 403) {
//                     alert("Session Expired")
//                     // setAuth(false);
//                 }
//                 resolve(result);
//             })
//             .catch(rejected => {
//                 reject({ err: rejected });
//             });
//     })
// }

export function dbSetUserDetails(options) {
  return new Promise((resolve, reject) => {
    options.data.usrPwd1 = Buffer.from(
      options.data.usrID + ":" + options.data.usrPwd1
    ).toString("base64");
    options.data.usrPwd2 = Buffer.from(
      options.data.usrID + ":" + options.data.usrPwd2
    ).toString("base64");
    var jsonReq = JSON.stringify(options.data);

    const accessToken = localStorage.getItem("accessToken");
    const httpHeaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "Authorization",
      Authorization: `Bearer ${accessToken}`,
    };

    const requestOptions = {
      method: "POST",
      headers: httpHeaders,
      body: jsonReq,
    };
    fetch(Constants.WSURL + "/Users/" + options.data.userid, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 403) {
          alert("Session Expired");
          // setAuth(false);
        }
        resolve(result);
      })
      .catch((rejected) => {
        reject({ err: rejected });
      });
  });
}

export function dbGetUserMgrList(options, callback) {
  const httpQuery = options.query;
  const accessToken = localStorage.getItem("accessToken");
  const httpHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "Authorization",
    Authorization: `Bearer ${accessToken}`,
  };

  const requestOptions = {
    method: "GET",
    headers: httpHeaders,
  };

  fetch(Constants.WSURL + "/Users/manager?" + httpQuery, requestOptions)
    .then((res) => res.json())
    .then((result) => {
      callback(result);
    })
    .catch((rejected) => {
      callback({ err: rejected });
    });
}
