export const SSRSURL_ENV = "";

// export const IMGURL = 'url(https://source.unsplash.com/user/llc2668/likes)'
// export const IMGURL = 'url(https://source.unsplash.com/1280x720/?random)'
// export const IMGURL = 'url(https://source.unsplash.com/1280x720/?nature)'

// DEV
// export const WSURL = "http://localhost:3333";
// export const WSURLFiles = "http://localhost:3333/anpUpload/";
// export const IMGURL =
//   "url(https://source.unsplash.com/1280x720/?workinprogress)";

// UAT
// export const WSURL = "http://bi.earth-my.com:3311";
// export const WSURLFiles = "http://bi.earth-my.com:3311/anpUpload/";
// export const IMGURL = "url(https://source.unsplash.com/1280x720/?flower)";

// PROD
export const WSURL = "http://bi.earth-my.com:3333";
export const WSURLFiles = "http://bi.earth-my.com:3333/files/upload/";
export const IMGURL = "url(https://source.unsplash.com/1280x720/?starfish)";
