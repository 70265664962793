import * as Constants from "./dbConnection";
// import React, { useContext } from "react";

// import { LoginContext } from '../contexts/LoginContext';

export function DBGetAnp(options) {
  return new Promise((resolve, reject) => {
    // const { profile } = useContext(LoginContext);
    // console("DBGetAnp", profile)

    const httpQuery = options.query;
    const httpPath = options.path;

    const accessToken = localStorage.getItem("accessToken");
    const httpHeaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "Authorization",
      Authorization: `Bearer ${accessToken}`,
    };

    const requestOptions = {
      method: "GET",
      headers: httpHeaders,
    };

    fetch(
      Constants.WSURL +
        "/anp" +
        (httpPath === undefined ? "" : "/" + httpPath) +
        "?" +
        httpQuery,
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 403) {
          alert("Session Expired");
          // setAuth(false);
        }
        resolve(result);
      })
      .catch((rejected) => {
        reject({ err: rejected });
      });
  });
}

export function DBSetAnp(data, options) {
  return new Promise((resolve, reject) => {
    // console.log("[DBSetAnp]")
    // console.log("- data ", data)
    // console.log("- option", options)

    const httpQuery = options.query;
    const httpPath = options.path;
    var jsonReq = JSON.stringify(data);

    // console.log("- jsonReq", jsonReq)

    const accessToken = localStorage.getItem("accessToken");
    const httpHeaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "Authorization",
      Authorization: `Bearer ${accessToken}`,
    };

    const requestOptions = {
      method: "PUT",
      headers: httpHeaders,
      body: jsonReq,
    };

    fetch(
      Constants.WSURL +
        "/anp" +
        (httpPath === undefined ? "" : "/" + httpPath) +
        "?" +
        httpQuery,
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 403) {
          alert("Session Expired");
          // setAuth(false);
        }
        resolve(result);
      })
      .catch((rejected) => {
        reject({ err: rejected });
      });
  });
}

export function dbGetSpendLocal(options, callback) {
  return {
    id: -1,
    pd: "KBC-HQ",
    dte: "2021-08-09T16:00:00.000Z",
    cst: {
      label: "PASAR MINI SING KEAN JOO ",
      value: "BS-300S/178",
    },
    expTyp: "408-103 ",
    dteFrom: "2021-08-01T16:00:00.000Z",
    dteTo: "2021-08-03T16:00:00.000Z",
    rmk: "Remarks",
    amt: 0,
    spend: [
      { product: "Brand 10", amount: 100 },
      { product: "Brand 20", amount: 200.12 },
      { product: "Brand 30", amount: 300.13 },
      { product: "Brand 40", amount: 400.14 },
    ],
    approver: [
      {
        id: 1,
        appr: "Requester's Name",
        apprDte: "2021-08-12T16:00:00.000Z",
        apprRmk: "Request",
      },
      {
        id: 2,
        appr: "Head Of Department",
        apprDte: "2021-08-13T16:00:00.000Z",
        apprRmk: "Approved",
      },
      { id: 3, appr: "General Manager", apprDte: null, apprRmk: null },
    ],
    jv: [
      {
        id: 1,
        jvNo: "JV-0001",
        jvDte: "2021-08-01T08:00:00.000Z",
        jvAmt: 1000,
        jvTotal: 1000,
      },
      {
        id: 2,
        jvNo: "JV-0002",
        jvDte: "2021-08-02T08:00:00.000Z",
        jvAmt: 2000,
        jvTotal: 3000,
      },
    ],
    cn: [
      {
        id: 1,
        dnDte: "2021-08-01T08:00:00.000Z",
        dnNo: "DN-001",
        dnAmt: 1000,
        cnDte: "2021-08-13T16:00:00.000Z",
        cnNo: "CN-001",
        cnBal: 1000,
      },
      {
        id: 2,
        dnDte: "2021-08-02T08:00:00.000Z",
        dnNo: "DN-002",
        dnAmt: 2000,
        cnDte: "2021-08-13T16:00:00.000Z",
        cnNo: "CN-002",
        cnBal: 3000,
      },
    ],
    recStatus: null,
    recCreatedBy: null,
    recCreatedOn: null,
    recModifiedBy: null,
    recModifiedOn: null,
  };
}

export function dbGetSpend(options, callback) {
  const httpQuery = options.query;
  const httpPath = options.path;

  // console.log("httpQuery", httpQuery)
  const accessToken = localStorage.getItem("accessToken");
  const httpHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "Authorization",
    Authorization: `Bearer ${accessToken}`,
  };

  const requestOptions = {
    method: "GET",
    headers: httpHeaders,
  };

  fetch(
    Constants.WSURL +
      "/anp" +
      (httpPath === undefined ? "" : "/" + httpPath) +
      "?" +
      httpQuery,
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      // console.log("anp get ", result)
      if (result.status === 403) {
        alert("Session Expired");
        // setAuth(false);
      }
      callback(result);
    })
    .catch((rejected) => {
      console.log("Rejected");
      callback({ err: rejected });
    });
}

export function dbSetSpend(data, callback) {
  const accessToken = localStorage.getItem("accessToken");
  var jsonReq = JSON.stringify(data);
  const httpHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "Authorization",
    Authorization: `Bearer ${accessToken}`,
  };

  // console.log("Set Spend Services", jsonReq);
  const requestOptions = {
    method: "POST",
    headers: httpHeaders,
    body: jsonReq,
  };
  fetch(Constants.WSURL + "/anp", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      // console.log("anp get ", result)
      if (result.status === 403) {
        alert("Session Expired");
        // setAuth(false);
      }
      console.log(" To Return", result);
      callback(result);
    })
    .catch((rejected) => {
      console.log("Rejected");
      callback({ err: rejected });
    });

  // fetch(Constants.WSURL + '/anp', requestOptions)
  //     .then(res => {
  //         // console.log("result ", res);
  //         if (res.status === 403) {
  //             callback(res);
  //             // console.log("logout la");
  //             // window.location.href = '/SignIn';
  //         } else {
  //             res.json()
  //             console.log("result res.json() " ,  res);
  //         }
  //     })
  //     // .then(res => res.json())
  //     .then(result => {
  //         console.log("Spend Service Result ", result);
  //         callback(result);
  //     })
  //     .catch(rejected => {
  //         console.log("Rejected ", rejected);
  //         callback(rejected);
  //     });
}

export function dbDelSpend(param, callback) {
  const URLQuery =
    "?proGrpNme=" +
    encodeURIComponent(param.proGrpNme) +
    "&proCstGrp=" +
    encodeURIComponent(param.proCstGrp);

  // console.log("URLQuery", URLQuery)
  const accessToken = localStorage.getItem("accessToken");
  const httpHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "Authorization",
    Authorization: `Bearer ${accessToken}`,
  };

  const requestOptions = {
    method: "DELETE",
    headers: httpHeaders,
  };

  fetch(Constants.WSURL + "/Promotions" + URLQuery, requestOptions)
    .then((res) => res.json())
    .then((result) => {
      // console.log('result', result)
      callback(result);
    })
    .catch((rejected) => {
      console.log("Rejected ", rejected);
      callback(rejected);
    });
}
