import React, { Component } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
// import dayGridPlugin from '@fullcalendar/daygrid';

// import { Panel } from 'primereact/panel';
// import { Chart } from 'primereact/chart';
// import { FullCalendar } from 'primereact/fullcalendar';

import { DBGetAnp } from '../services/SpendService';

export class Dashboard extends Component {

    constructor() {
        super();
        this.state = {
            recYear: 0,
            recStatus: { budget:0,  raised: 0, approved: 0,  balance: 0, claimed: 0, unclaimed: 0},
            spend: [{ ExpTyp: "", Budget: 0, Raised : 0, Approved: 0, Reversed : 0, JV : 0 ,Claimed: 0, Balance: 0, Unclaimed: 0 }],
            // events: [{ id: null, title: null, start: null, end: null }],

            // tasks: [],
            // city: null,
            // selectedCar: null,
            // lineData: {
            //     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            //     datasets: [
            //         {
            //             label: 'Approved',
            //             data: [50, 59, 80, 81, 56, 55, 40],
            //             // fill: false,
            //             backgroundColor: '#007be5',
            //         },
            //         {
            //             label: 'Claimed',
            //             data: [28, 48, 40, 19, 86, 27, 90],
            //             fill: false,
            //             backgroundColor: '#20d077'
            //         }
            //     ]
            // },
            // fullcalendarOptions: {
            //     plugins: [dayGridPlugin],
            //     // plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            //     defaultDate: '2021-08-01',
            //     initialView: "dayGridMonth",
            //     header: {
            //         left: 'prev,next today',
            //         center: 'title',
            //         right: 'month,agendaWeek,agendaDay'
            //     },
            //     editable: true
            // },

        };
    }

    amountTemplate(inValue) {
        // console.log("inValue ", inValue)
        let value = (inValue||0)
        return value.toLocaleString(undefined,
            { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 });
    }

    setDbYear( yy ) {
        this.setState( {recYear : yy})
        this.refreshDashboard(yy) ;
    }

    refreshDashboard( yy )  {
        DBGetAnp({ query: "option=recStatus&type=summ&filter=yy= "+ yy  })
            .then((result) => {
                if (result.status === 201) {
                    console.log('result', result)
                    this.setState({ recStatus: result.data[0] });
                } else {
                    this.setState({ recStatus: {} });
                    console.log('no result', result)
                }
            })
            .catch(rejected => {
                console.log("DBGetAnp  : option=recStatus&type=summ&filter=yy= "+ yy, rejected)
            });
            
        // this.setState({ spend: {} });
        DBGetAnp({ query: "option=exptyp&type=summ&filter=yy= "+ yy })
            .then((result) => {
                if (result.status === 201) {
                     console.log('result - exptyp', result)
                    this.setState({ spend: result.data });
                } else {
                     console.log('no result- exptyp ', result)
                    this.setState({ spend: {} });
                }
            })
            .catch(rejected => {
                console.log("DBGetAnp  : option=exptyp&type=summ&filter=yy=" + yy, rejected)
            });
    }


    componentDidMount() {
        const date = new Date() ;
        // this.state.recYear = date.getFullYear();
        // console.log("mount" ) ;
        this.setDbYear( date.getFullYear())
        // this.refreshDashboard( this.state.recYear ) ;


        // DBGetAnp({ query: "option=event&type=calender" })
        //     .then((result) => {
        //         if (result.status === 201) {
        //             this.setState({ events: result.data });
        //         }
        //     });

        // DBGetAnp({ query: "option=byMM&type=list" })
        //     .then((result) => {
        //         if (result.status === 201) {

        //             console.log("result byMM", result)
        //             let newData = {
        //                 labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        //                 datasets: [
        //                     {
        //                         label: 'Approved',
        //                         data: [50, 59, 80, 81, 56, 55, 40, 22],
        //                         // fill: false,
        //                         backgroundColor: '#007be5',
        //                     },
        //                     {
        //                         label: 'Claimed',
        //                         data: [28, 48, 40, 19, 86, 27, 90],
        //                         fill: false,
        //                         backgroundColor: '#20d077'
        //                     }
        //                 ]
        //             }
        //             this.setState({ lineData: newData });
        //         }
        //     });
    }

    render() {
        return (
            <div className="p-grid p-fluid dashboard">
                <div className="p-col-12 p-lg-4">
                    <div className="card summary">
                        <span className="title">Year</span>
                        <span className="detail">Budget Year</span>
                        <span className="count" >
                             <InputNumber style={{width: "6vw"}} showButtons format={false} value={this.state.recYear} onValueChange={(e) => this.setDbYear(e.value)}  />
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="card summary">
                        <span className="title">Budget</span>
                        <span className="detail">{this.state.recYear} Budget</span>
                        <span className="count budget">{this.amountTemplate(this.state.recStatus.budget)}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="card summary">
                        <span className="title">Budget Balance</span>
                        <span className="detail">{this.state.recYear} budget balance</span>
                        <span className="count budget">{this.amountTemplate(this.state.recStatus.balance)}</span>
                    </div>
                </div>

                <div className="p-grid p-col-12 p-lg-7">
                    <div className="p-col-12 p-lg-4">
                        <div className="card summary">
                            <span className="title">Raised</span>
                            <span className="detail">{this.state.recYear} A&P Raised</span>
                            <span className="count raised">{this.amountTemplate(this.state.recStatus.raised)}</span>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-4">
                        <div className="card summary">
                            <span className="title">Approved</span>
                            <span className="detail">{this.state.recYear} A&P Approved</span>
                            <span className="count approved">{this.amountTemplate(this.state.recStatus.approved)}</span>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-4">
                        <div className="card summary">
                            <span className="title">Reversed</span>
                            <span className="detail">{this.state.recYear} Closed/Released</span>
                            <span className="count raised">{this.amountTemplate(this.state.recStatus.reversed)}</span>
                        </div>
                    </div>
                </div>
                <div className="p-grid p-col-12 p-lg-5">
                    <div className="p-col-12 p-lg-6">
                        <div className="card summary">
                            <span className="title">Claimed</span>
                            <span className="detail">{this.state.recYear} Total A&P Claimed</span>
                            <span className="count claimed">{this.amountTemplate(this.state.recStatus.claimed)}</span>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="card summary">
                            <span className="title">Unclaimed</span>
                            <span className="detail">{this.state.recYear} Total A&P Unclaimed</span>
                            <span className="count unclaim">{this.amountTemplate(this.state.recStatus.unclaimed)}</span>
                        </div>
                    </div >
                </div >

                <div className="p-grid p-col-12">
                    <div className="p-col-12 p-md-6 p-xl-2">
                        <div className="highlight-box">
                            <div className="initials dashboard_iconbox" style={{ backgroundColor: '#007be5' }}><i className="pi pi-book" style={{ 'fontSize': '3em' }} /></div>
                            <div className="highlight-details ">
                                {/* <i className="pi pi-book" /> */}
                                <span>New</span>
                                <span className="count">{this.state.recStatus.N_cnt}</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-2">
                        <div className="highlight-box">
                            <div className="initials dashboard_iconbox" style={{ backgroundColor: '#f9c851' }}><i className="pi pi-question-circle" style={{ 'fontSize': '3em' }} /></div>
                            <div className="highlight-details ">
                                {/* <i className="pi pi-question-circle" /> */}
                                <span>Pending</span>
                                <span className="count">{this.state.recStatus.AP_cnt}</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-2">
                        <div className="highlight-box">
                            <div className="initials dashboard_iconbox" style={{ backgroundColor: 'rgb(239, 98, 98)' }}><i className="pi pi-thumbs-down" style={{ 'fontSize': '3em' }} /></div>
                            <div className="highlight-details ">
                                {/* <i className="pi pi-thumbs-down" /> */}
                                <span>Rejected</span>
                                <span className="count">{this.state.recStatus.R_cnt}</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-2">
                        <div className="highlight-box">
                            <div className="initials dashboard_iconbox" style={{ backgroundColor: 'rgb(239, 98, 98)' }}><i className="pi pi-trash" style={{ 'fontSize': '3em' }} /></div>
                            <div className="highlight-details ">
                                {/* <i className="pi pi-trash" /> */}
                                <span>Cancelled</span>
                                <span className="count">{this.state.recStatus.X_cnt}</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-2">
                        <div className="highlight-box">
                            <div className="initials dashboard_iconbox" style={{ backgroundColor: '#20d077' }}><i className="pi pi-thumbs-up" style={{ 'fontSize': '3em' }} /></div>
                            <div className="highlight-details ">
                                {/* <i className="pi pi-thumbs-up" /> */}
                                <span>Approved</span>
                                <span className="count">{this.state.recStatus.A_cnt}</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-2">
                        <div className="highlight-box">
                            <div className="initials dashboard_iconbox" style={{ backgroundColor: '#20d077' }}><i className="pi pi-lock" style={{ 'fontSize': '3em' }} /></div>
                            <div className="highlight-details ">
                                {/* <i className="pi pi-lock" /> */}
                                <span>Closed</span>
                                <span className="count">{this.state.recStatus.C_cnt}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-lg-12">
                    <div className="card">
                        <h1 style={{ fontSize: '16px' }}>Expense Type ({this.state.recYear})</h1>
                        <DataTable value={this.state.spend} style={{ marginBottom: '20px' }} responsive={true}
                        // selectionMode="single" selection={this.state.spend} onSelectionChange={(e) => this.setState({selectedCar: e.value})}
                        >
                            <Column field="ExpTyp" header="Expense Type" tyle={{ width: '20%' }} sortable={true} />
                            <Column field="Budget" header="Budget" sortable={true} style={{ width: '10%',  textAlign: 'right'  }} body={(rowData) => this.amountTemplate(rowData['Budget'])} className="columnRight" />
                            <Column field="Approved" header="Approved" sortable={true} style={{ width: '10%',  textAlign: 'right'  }} body={(rowData) => this.amountTemplate(rowData['Approved'])} className="columnRight" />
                            <Column field="Reversed" header="Reversed" sortable={true} style={{ width: '10%',  textAlign: 'right'  }} body={(rowData) => this.amountTemplate(rowData['Reversed'])} className="columnRight" />
                            <Column field="Balance" header="Balance" sortable={true} style={{ width: '10%',  textAlign: 'right'  }} body={(rowData) => this.amountTemplate(rowData['Balance'])} className="columnRight" />
                            <Column field="Claimed" header="Claimed" sortable={true} style={{ width: '10%',  textAlign: 'right'  }} body={(rowData) => this.amountTemplate(rowData['Claimed'])} className="columnRight" />
                            <Column field="Unclaimed" header="Unclaimed" sortable={true} style={{ width: '10%',  textAlign: 'right'  }} body={(rowData) => this.amountTemplate(rowData['Unclaimed'])} className="columnRight" />
                            <Column field="JV" header="JV" sortable={true} style={{ width: '10%',  textAlign: 'right'  }} body={(rowData) => this.amountTemplate(rowData['JV'])} className="columnRight" />
                        </DataTable>
                    </div>
                </div>
                {/* <div className="p-col-12 p-lg-6"> */}
                {/* <div className="card">
                        <Chart type="bar" data={this.state.lineData} height='60%' />
                    </div> */}
                {/* <Panel header="Event Calendar" style={{ height: '50%' }}>
                        <FullCalendar
                            events={this.state.events}
                            options={this.state.fullcalendarOptions}
                        ></FullCalendar>
                    </Panel> */}
                {/* <div className="card">
                        <h1 style={{ fontSize: '16px' }}>Expense Type (Curren Year)</h1>
                        <DataTable value={this.state.spend} style={{ marginBottom: '20px' }} responsive={true}
                        // selectionMode="single" selection={this.state.spend} onSelectionChange={(e) => this.setState({selectedCar: e.value})}
                        >
                            <Column field="ExpTyp" header="Expense Type" sortable={true} />
                            <Column field="Approved" header="Appr" sortable={true} style={{ width: '20%' }} body={(rowData) => this.amountTemplate(rowData['Approved'])} className="columnRight" />
                            <Column field="Claimed" header="Claim" sortable={true} style={{ width: '20%' }} body={(rowData) => this.amountTemplate(rowData['Claimed'])} className="columnRight" />
                            <Column field="Balance" header="Bal" sortable={true} style={{ width: '20%' }} body={(rowData) => this.amountTemplate(rowData['Balance'])} className="columnRight" />
                        </DataTable>
                    </div> */}
                {/* </div> */}
            </div >
        );
    }
}