import { useHistory } from "react-router-dom";
// import React, { useContext, useState, useEffect, useRef, lazy } from 'react';
import React, { useContext, useState, useEffect, useRef } from "react";

// import { NavigationContainer } from '@react-navigation/native';
// import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { dbGetSpend, dbSetSpend } from "../services/SpendService";

import {
  refGetRecStatusButton,
  refGetRecStatusLabel,
} from "../services/ReferenceService";

import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { InputNumber } from 'primereact/inputnumber';
// import { InputText } from 'primereact/inputtext';
// import { MultiSelect } from 'primereact/multiselect';
// import { Tooltip } from 'primereact/tooltip';

// import classnames from 'classnames';
import { Dropdown } from "primereact/dropdown";

import { LoginContext } from "../contexts/LoginContext";
// import AllDaySplitter from "@fullcalendar/timegrid/AllDaySplitter";
import { saveAs } from "file-saver";

export default function AnpRequestGrid() {
  // const { setProfile, profile } = useContext(LoginContext);
  const { profile, setProfile } = useContext(LoginContext);
  const history = useHistory();
  const toast = useRef(null);

  const [loading, setLoading] = useState(false);

  // const [selectedCustomers, setSelectedCustomers] = useState(null);
  // console.log("selectedCustomers ", selectedCustomers)
  // let selectedCustomers = null
  const [spend, setSpend] = useState([]);

  const [showFilter, setShowFilter] = useState(false);
  // const [filters1, setFilters1] = useState(null);
  // const [globalFilterValue1, setGlobalFilterValue1] = useState('');

  // const [recId, setRecId] = useState(null);
  // const [recAction, setRecAction] = useState(null);
  const [recControl, setRecControl] = useState({
    id: null,
    recStatus: "N",
    readOnly: false,
    showFinanceAppr: false,
    showFinanceDiv: false,
    recAction: null,
  });

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const [totalRecords, setTotalRecords] = useState(0);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filterSet: false,
    filters: {
      id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      dte: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      sqlAccCde: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      pdNme: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      cst: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      cstNme: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      amt: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      dtePay: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      recStatus: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      expTypD: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      jvAmt: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      jvBal: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      dnAmt: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      dnBal: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    },
    sortMeta: [],
  });

  // let multiSortMeta = [];

  // const dt = useRef(null);

  const header = (
    <div className="table-header">
      A&P Request List
      <div className="p-tablebuttonset">
        <Button
          type="button"
          tooltip="Create New Request"
          icon="pi pi-plus"
          className="p-button-rounded p-button-success"
          onClick={() => (window.location = "#/anpRequest")}
        />
        <Button
          type="button"
          tooltip="Filter"
          icon={showFilter ? "pi pi-filter-slash" : "pi pi-filter"}
          className="p-button-rounded p-button-warning"
          onClick={() => setShowFilter(!showFilter)}
        />
        <Button
          type="button"
          tooltip="Refresh"
          icon="pi pi-refresh"
          className="p-button-rounded p-button-success"
          onClick={(e) => tableRefresh("list")}
        />
        <Button
          type="button"
          tooltip="Export to Excel"
          label="Export"
          icon="pi pi-file-excel"
          className="p-button-rounded p-button-warning"
          onClick={(e) => exportExcel(e)}
        />
      </div>
    </div>
  );

  const exportExcel = () => {
    tableRefresh("export");

    // if (spend.length > 0) {
    //     import('xlsx').then(xlsx => {
    //         const worksheet = xlsx.utils.json_to_sheet(spend);
    //         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //         const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //         saveAsExcelFile(excelBuffer, 'spend');
    //     });
    // }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const dateBodyTemplate = (rowData, field) => {
    if (rowData[`${field}`] === undefined) {
      return null;
    } else {
      let dateWithouthSecond = new Date(rowData[`${field}`]);
      return dateWithouthSecond.toLocaleString("en-GB", {
        dateStyle: "medium",
      });
    }
  };

  const dateMMMYYBodyTemplate = (rowData, field) => {
    if (rowData[`${field}`] === undefined) {
      return null;
    } else {
      let dateWithouthSecond = new Date(rowData[`${field}`]);
      return dateWithouthSecond.toLocaleString("en-GB", {
        year: "numeric",
        month: "short",
      });
    }
  };

  // const filterFromBackend = () => {
  //     alert('go get new data from database');
  //     return false
  // }

  const amountBodyTemplate = (rowData, field) => {
    let value = rowData[`${field}`];
    if (value === undefined || value === 0) return null;
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const recStatusBodyTemplate = (rowData) => {
    return refGetRecStatusButton(rowData.recStatus);
  };

  const recStatusFilterTemplate = (recStatus) => {
    return refGetRecStatusButton(recStatus);
  };

  // const onStatusChange = (event) => {
  //     dt.current.filter(event.value, 'recStatus', 'equals');
  //     setRecStatus(event.value);
  // }

  const tableRefresh = (qryTyp) => {
    // Sort Data
    let wsOrder = "";
    let sqlOrder = "";
    lazyParams.sortMeta.forEach((fld, index) => {
      sqlOrder = [sqlOrder, index === 0 ? "" : " , ", fld.field].join(" ");
      sqlOrder = [sqlOrder, fld.order === 1 ? "" : " desc"].join("");
    });

    if (sqlOrder !== "") {
      wsOrder = sqlOrder;
    }

    let wsFilter = "";

    if (showFilter) {
      for (const fld in lazyParams.filters) {
        let sqlFilter = "";
        for (const v in lazyParams.filters[fld]["constraints"]) {
          if (lazyParams.filters[fld]["constraints"][v].value !== null) {
            let fldOperator = lazyParams.filters[fld]["operator"];
            let fldFilter = getFilterOperator(
              lazyParams.filters[fld]["constraints"][v].matchMode,
              lazyParams.filters[fld]["constraints"][v].value
            );
            sqlFilter = [
              sqlFilter,
              v === 0 ? "" : fldOperator,
              fld,
              fldFilter,
            ].join(" ");
          }
        }
        if (sqlFilter !== "") {
          wsFilter = [wsFilter, sqlFilter].join("");
        }
      }
    }

    let pageNo = parseInt(lazyParams.page) + 1;
    // setLoading( true)
    dbGetSpend(
      {
        query:
          "option=hdr&type=" +
          qryTyp +
          "&page=" +
          pageNo +
          "&rowLimit=" +
          lazyParams.rows +
          "&filter=" +
          wsFilter +
          "&order=" +
          wsOrder,
      },
      (result) => {
        if (result.status === 201) {
          console.log(result);
          if (qryTyp === "list") {
            setSpend(result.data);
            setTotalRecords(result.recCount);
            // calcRowTotal()
          } else {
            // exportData( result.data )
            import("xlsx").then((xlsx) => {
              const worksheet = xlsx.utils.json_to_sheet(result.data);
              const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
              };
              const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
              });
              saveAsExcelFile(excelBuffer, "spend");
            });
          }
          setLoading(false);
        } else {
          setSpend([]);
        }
      }
    );
  };

  const onTablePage = (event) => {
    event.filters = lazyParams.filters;
    event.sortMeta = lazyParams.sortMeta;
    setLazyParams(event);
  };

  const onTableSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);

    setLazyParams((prevState) => {
      return {
        ...prevState,
        sortMeta: [{ field: event.sortField, order: event.sortOrder }],
      };
    });
  };

  const onFilter = (event) => {
    setLazyParams((prevState) => {
      return {
        ...prevState,
        filters: event.filters,
      };
    });
  };

  // const calcRowTotal = () => {
  //     let spendData =spend
  //     let rowAmt = 0
  //     let rowProv = 0
  //     let rowBal = 0
  //     spendData.reduce((a, v) => rowAmt = a + v.amt, 0)
  //     spendData.reduce((a, v) => rowProv = a + v.amt, 0)
  //     spendData.reduce((a, v) => rowBal = a + v.amt, 0)

  //     setSpend({ ...spend, totalAmt: rowAmt, totalProv: rowProv, totalBal: rowBal })
  // }

  // const footerTemplate =
  //   <div style={{ height: 30 }}>
  //     <div style={{height: 30, width: '100%', float: 'left' }}>
  //         <div style={{ height: 30, width: '60%', float: 'left'}}>Total </div>
  //         <div style={{ height: 30, width: '15%', float: 'left' }}>
  //             <InputText
  //                 value={spend.provTotal}
  //                 style={{ marginTop: -24, textAlign: 'right' }}
  //                 className="p-tableFooter"
  //                 readOnly mode="decimal"
  //                 minFractionDigits={2} />
  //         </div>
  //         <div style={{ height: 30, width: '15%', float: 'left'}}>
  //             <InputText
  //                  value={spend.balTotal}
  //                 style={{ marginTop: -24, textAlign: 'right'   }}
  //                 className="p-tableFooter"
  //                 readOnly mode="decimal"
  //                 minFractionDigits={2} />
  //         </div>
  //     </div>
  // </div>

  const getFilterOperator = (operator, value) => {
    let rtnVal = null;
    switch (operator) {
      case "startsWith":
        rtnVal = " like '" + value + "^'";
        break;
      case "endsWith":
        rtnVal = " like '^" + value + "'";
        break;
      case "contains":
        rtnVal = " like '^" + value + "^'";
        break;
      case "notContains":
        rtnVal = " not like '^" + value + "^'";
        break;
      case "equals":
        rtnVal = " = '" + value + "'";
        break;
      case "notEquals":
        rtnVal = " != '" + value + "'";
        break;
      case "gt":
        rtnVal = " > " + value;
        break;
      case "lt":
        rtnVal = " < " + value;
        break;
      case "gte":
        rtnVal = " >= " + value;
        break;
      case "lte":
        rtnVal = " <= " + value;
        break;
      case "dateIs":
        rtnVal = " = '" + value + "'";
        break;
      case "dateBefore":
        rtnVal = " < '" + value + "'";
        break;
      case "dateAfter":
        rtnVal = " > '" + value + "'";
        break;
      default:
        rtnVal = operator;
    }
    return rtnVal;
  };

  const onRowDblclick = (e) => {
    // alert("Hello : " + e.data.id)
    //  console.log("lazyParams", lazyParams)
    // window.location = "#/anpRequest?id=" + e.data.id
    setProfile({ ...profile, lazyParams: lazyParams, showFilter: showFilter });
    history.push("/anpRequest?id=" + e.data.id, lazyParams);
  };

  // let statusFilterTemplate = <Dropdown style={{ width: '100%' }} className="ui-column-filter"
  //     value={recStatus} options={recStatuses} onChange={onStatusChange} />

  const filterStatus = ["N", "AP", "A", "R", "C"];

  const statusItemTemplate = (option) => {
    // let statusIcon = recStatusBodyTemplate(option)
    return (
      <div className="p-multiselect-representative-option">
        {recStatusFilterTemplate(option)}
        {refGetRecStatusLabel(option)}
      </div>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={filterStatus}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  // const [rec, setRec] = useState({ readOnly: false, showFinanceAppr: false });

  const rec = { readOnly: false, showFinanceAppr: false };

  const onRowAction = (rowData, action) => {
    // setRecId( rowData.id)
    // setRecAction( action )
    recControl.id = rowData.id;
    recControl.recAction = action;
    setRecControl(recControl);
    setShowConfirmDialog(true);
  };

  const onRowActionConfirmed = () => {
    // alert("delete from " + activeTable.)
    let data = {};
    data.id = recControl.id;
    data.cn = [];
    data.jv = [];
    data.filelist = [];
    data.filelist_Ori = [];
    data.action = recControl.recAction;

    dbSetSpend(data, (result) => {
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "ANP No : " + recControl.id + " " + recControl.recAction,
        life: 1500,
      });
    });
    setShowConfirmDialog(false);
  };

  const actionBodyTemplate = (rec) => {
    const canCancel = ["N", "AP"];
    const canClose = ["A"];
    const authUsrRole = ["admin", "finAdmin"];
    return (
      <React.Fragment>
        {canCancel.indexOf(rec.recStatus) !== -1 &&
        authUsrRole.indexOf(profile.usrRole) !== -1 ? (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger"
            onClick={() => onRowAction(rec, "Cancel")}
            tooltip="Cancel"
          />
        ) : null}
        {canClose.indexOf(rec.recStatus) !== -1 &&
        authUsrRole.indexOf(profile.usrRole) !== -1 ? (
          <Button
            type="button"
            icon="pi pi-lock"
            className="p-button-rounded p-button-warning"
            onClick={() => onRowAction(rec, "Close")}
            tooltip="Close"
          />
        ) : null}
      </React.Fragment>
    );
  };

  const actionDialogFooter = () => {
    return (
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => {
            setShowConfirmDialog(false);
          }}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={onRowActionConfirmed}
        />
      </React.Fragment>
    );
  };

  // const onRowAction = (props) => {
  //     // let data = getValues(props)
  //     // let idx = data.length + 10
  //     // data = data.concat({ "id": idx })
  //     // setValue(props, data)
  //     alert(props.id)
  // }

  useEffect(() => {
    // alert('Use Effect - Reload liow')
    setProfile({ ...profile, activeMenuName: "A&P List" });
    // console.log("effect ", profile.lazyParams)
    // console.log("typeof profile.lazyParams ", typeof profile.lazyParams)

    if (typeof profile.lazyParams !== "undefined") {
      //    console.log('before ' , profile)
      setLazyParams(profile.lazyParams);
      setShowFilter(true);
      setProfile({ ...profile, lazyParams: undefined });
    }
    tableRefresh("list");
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="p-md-12 p-sm-12">
        <div className="card card-w-title">
          <div className="p-grid">
            <div className="p-md-12 p-sm-12">
              <DataTable
                value={spend}
                style={{ marginBottom: "20px" }}
                lazy
                first={lazyParams.first}
                totalRecords={totalRecords}
                onPage={onTablePage}
                // footer={footerTemplate}
                // sortMode="multiple"
                onSort={onTableSort}
                sortField={sortField}
                sortOrder={sortOrder}
                multiSortMeta={lazyParams.sortMeta}
                loadingIcon="pi pi-spinner"
                loading={loading}
                className="p-datatable-sm"
                // responsive={true}
                // filterDisplay="row"
                responsiveLayout="scroll"
                paginator
                // ref={dt}
                rowHover
                // selection={selectedCustomers}
                onRowDoubleClick={(e) => onRowDblclick(e)}
                // filterDisplay="row"
                // filterFunction={filterFromBackend}
                // onFilter={(e) => onGlobalFilterChange1(e)}
                // paginatorLeft={paginatorLeft}
                // paginatorRight={paginatorRight}
                // onFilter={onFilter}
                // filters={lazyParams.filters}
                // filters={filters}
                // onChange={onGlobalFilterChange1}
                onFilter={onFilter}
                filters={lazyParams.filters}
                header={header}
                // filters={filters1}
                filterDisplay="menu"
                //  globalFilterFieslds={['id','dte','pdNme','cst','cstNme','amt','recStatus','expTypD']}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={lazyParams.rows}
                rowsPerPageOptions={[10, 20, 50, 100]}
              >
                {rec.readOnly ? null : (
                  <Column
                    body={(rowData) => actionBodyTemplate(rowData)}
                    style={{ width: "10px" }}
                  ></Column>
                )}
                <Column
                  field="id"
                  header="id"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "12px" }}
                />
                <Column
                  field="dte"
                  header="Date"
                  filterField="dte"
                  dataType="date"
                  filter={showFilter}
                  sortable={true}
                  body={(rowData) => dateBodyTemplate(rowData, "dte")}
                  style={{ width: "12px" }}
                />
                <Column
                  field="sqlAccCde"
                  header="Acc Code"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "12px" }}
                />
                <Column
                  field="pdNme"
                  header="Distributor"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "15%" }}
                />
                <Column
                  field="cst"
                  header="Code"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "12px" }}
                />
                <Column
                  field="cstNme"
                  header="Customer Name"
                  filter={showFilter}
                  sortable={true}
                  style={{ width: "20%" }}
                />
                <Column
                  dataType="numeric"
                  field="amt"
                  header="RM"
                  filter={showFilter}
                  sortable={true}
                  body={(rowData) => amountBodyTemplate(rowData, "amt")}
                  className="center"
                  style={{ width: "10px", textAlign: "right" }}
                />
                <Column
                  field="dtePay"
                  header="Pay Date"
                  filterField="dtePay"
                  dataType="date"
                  filter={showFilter}
                  sortable={true}
                  body={(rowData) => dateMMMYYBodyTemplate(rowData, "dtePay")}
                  style={{ width: "5px" }}
                />
                <Column
                  field="recStatus"
                  filterMenuStyle={{ width: "14rem" }}
                  filter={showFilter}
                  className="columnCenter"
                  filterElement={statusFilterTemplate}
                  sortable={true}
                  style={{ width: "30px" }}
                  body={(rowData) => recStatusBodyTemplate(rowData)}
                />
                <Column
                  dataType="numeric"
                  field="jvAmt"
                  header="JV"
                  filter={showFilter}
                  sortable={true}
                  body={(rowData) => amountBodyTemplate(rowData, "jvAmt")}
                  className="center"
                  style={{ width: "10px", textAlign: "right" }}
                />
                <Column
                  dataType="numeric"
                  field="jvAmtR"
                  header="JVR"
                  filter={showFilter}
                  sortable={true}
                  body={(rowData) => amountBodyTemplate(rowData, "jvAmtR")}
                  className="center"
                  style={{ width: "10px", textAlign: "right" }}
                />
                <Column
                  dataType="numeric"
                  field="jvBal"
                  header="JV Bal"
                  filter={showFilter}
                  sortable={true}
                  body={(rowData) => amountBodyTemplate(rowData, "jvBal")}
                  className="center"
                  style={{ width: "10px", textAlign: "right" }}
                />
                <Column
                  dataType="numeric"
                  field="dnAmt"
                  header="DN"
                  filter={showFilter}
                  sortable={true}
                  body={(rowData) => amountBodyTemplate(rowData, "dnAmt")}
                  className="center"
                  style={{ width: "10px", textAlign: "right" }}
                />
                <Column
                  dataType="numeric"
                  field="dnBal"
                  header="DN Bal"
                  filter={showFilter}
                  sortable={true}
                  body={(rowData) => amountBodyTemplate(rowData, "dnBal")}
                  className="center"
                  style={{ width: "10px", textAlign: "right" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        visible={showConfirmDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={actionDialogFooter}
        // onHide={showDelProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Do you want to {recControl.recAction} ANP {recControl.id}{" "}
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
}
