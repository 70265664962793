import React, { useState } from "react";
import { Home } from "./components/home";
import Login from "./components/login";

import { LoginContext } from "./contexts/LoginContext";

export default function App() {
  const [auth, setAuth] = useState(false);
  const [profile, setProfile] = useState({
    userName: "Unknown User",
    userMenu: "00000000",
    activeMenuName: "ANP",
    layoutMode: "static",
    layoutColorMode: "white",
    staticMenuInactive: false,
    overlayMenuActive: false,
    mobileMenuActive: false,
  });
  // useEffect(() => {

  // });

  return (
    <div className={auth ? null : "div-NoOverflow"}>
      <LoginContext.Provider value={{ auth, setAuth, profile, setProfile }}>
        {/* {(profile.menu === 'ANP') ? <Home /> : <Login />} */}
        {auth ? <Home /> : <Login />}
      </LoginContext.Provider>
    </div>
  );
}
