import React, { useContext } from 'react';

import * as Constants from '../services/dbConnection'

import { useForm, Controller } from "react-hook-form";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AppFooter } from '../AppFooter';


import { LoginContext } from '../contexts/LoginContext';

import { dbUserSignOn, dbGetUser } from '../services/UserService';

import classnames from 'classnames';

export default function Login() {
    const defaultValues = { "usrID": '', "usrPassword": '' }

    const { setAuth, setProfile } = useContext(LoginContext);
    // const { control, formState: { errors }, setError, handleSubmit } = useForm(defaultValues);
    const { control, formState: { errors }, setError, handleSubmit } = useForm(defaultValues);
    // const [formShowMessage, setFormShowMessage] = useState(false);

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = async (data) => {
        //   console.log("onSubmit data", data)

        await dbUserSignOn({ userid: data.usrID, password: data.usrPassword })
            .then(recID => {
                if (recID) {
                    // console.log( "User from DB ", recID )
                    dbGetUser({ query: "type=id" , path : recID })
                    // dbGetUser({ query: "option=hdr&type=rec&filter=usrId='" + data.usrID + "'" })
                    .then(result => {
                            const usrRec = result.data[0];
                            setProfile({
                                userID: usrRec.usrID,
                                userName: usrRec.usrNme,
                                userMenu: usrRec.usrMenu,
                                usrRole: usrRec.usrRole,
                                activeMenuName: "ANP",
                                layoutMode: 'static',
                                layoutColorMode: 'dark',
                                staticMenuInactive: false,
                                overlayMenuActive: false,
                                mobileMenuActive: false,
                                viewBudgetCom: usrRec.usrViewBudgetCom
                            })
                            setAuth(true);
                    })
                    .catch(rejected => {
                        console.log("Rejected : ", rejected)
                    });
                }
            })
            .catch(rejected => {
                // console.log("Rejected : ", rejected)
                setError("usrPassword", {
                    type: "manual",
                    message: "Invalid user ID or Password !",
                });
            });

    }

    // const onSubmit = async (data) => {
    //     console.log("Clicked")
    //     dbUserSignOn({ userid: data.usrID, password: data.usrPassword }, (authorised) => {
    //         // alert(authorised)
    //          console.log("gbGetuser b4")
    //         if (authorised) {
    //             // console.log("Get user profile for ", data.usrID)
    //             let pageNo = 1
    //             let qryTyp = 'rec'
    //             let lazyParams = {rows : 1}
    //             let wsFilter = ''
    //             let wsOrder = ''

    //             let id = 0

    //             dbGetUser({ query: "option=hdr&type=id", path: id })
    //             .then(result => {
    //                     console.log("gbGetuser")

    //                     const usrRec = result.data[0];
    //                     setProfile({
    //                         userID: usrRec.usrID,
    //                         userName: usrRec.usrNme,
    //                         userMenu: usrRec.usrMenu,
    //                         activeMenuName: "ANP",
    //                         layoutMode: 'static',
    //                         layoutColorMode: 'dark',
    //                         staticMenuInactive: false,
    //                         overlayMenuActive: false,
    //                         mobileMenuActive: false,
    //                         viewBudgetCom: usrRec.usrViewBudgetCom
    //                     })
    //                     setAuth(true);
    //             })
    //             .catch(rejected => {
    //                 console.log("Rejected : ", rejected)
    //             });

    //             // dbGetUser({ query: "option=id&type=" + qryTyp + "&page=" + pageNo + "&rowLimit=" + lazyParams.rows + "&filter=" + wsFilter + "&order=" + wsOrder }, (result) => {

    //             // // dbGetUser(data.usrID, (result) => {
    //             //     if (result.status === 201) {
    //             //         const usrRec = result.data[0];
    //             //         setProfile({
    //             //             userID: usrRec.usrID,
    //             //             userName: usrRec.usrNme,
    //             //             userMenu: usrRec.usrMenu,
    //             //             activeMenuName: "ANP",
    //             //             layoutMode: 'static',
    //             //             layoutColorMode: 'dark',
    //             //             staticMenuInactive: false,
    //             //             overlayMenuActive: false,
    //             //             mobileMenuActive: false,
    //             //             viewBudgetCom: usrRec.usrViewBudgetCom
    //             //         })
    //             //         setAuth(true);
    //             //     }
    //             // })
    //         } else {
    //             setError("usrPassword", {
    //                 type: "manual",
    //                 message: "Invalid user ID or Password !",
    //             });
    //         }
    //     });
    // };

    return (
        <div className="p-grid" >
            <div className="p-xl-8 p-lg-8 p-md-8 p-sm-8">
                <div className="imageClass" style={{
                   height: "97vh",
                    // width: "65vw",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: Constants.IMGURL,
                }}>
                </div>
            </div>
            {/* <div className="p-col-11"> */}
            <div className="p-xl-4 p-lg-4 p-md-4 p-sm-4">
                <div className="card">
                    <div className="layout-login-logo-company">
                        <img alt="Logo" src='assets/layout/images/logo-black.png' />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className="layout-login-logo">
                            <img src="assets/layout/images/login.png" alt="" />
                        </div>
                        <div className="layout-login-logo">
                            <p></p>
                            <div className="p-inputgroup p-loginGroup" style={{ width: "50%" }}>
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <Controller name="usrID" control={control} rules={{ required: 'User ID is required.' }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name}  {...field}
                                            autoFocus
                                            className={classnames({ 'p-invalid': fieldState.invalid })}
                                            placeholder="User ID" />
                                    )} />
                                <p></p>

                            </div>
                            {getFormErrorMessage('usrID')}
                        </div>
                        <p></p>
                        <p></p>
                        <div className="layout-login-logo">
                            <div className="p-inputgroup p-loginGroup" style={{ width: "50%" }}>
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-unlock"></i>
                                </span>
                                <Controller name="usrPassword" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                    <InputText
                                        type='password'
                                        id={field.name}  {...field}
                                        // value={field.value}
                                        className={classnames({ 'p-invalid': fieldState.invalid })}
                                        placeholder="Password" />
                                )} />
                            </div>
                            {getFormErrorMessage('usrPassword')}
                        </div>
                        <p></p>
                        <p></p>
                        <div className="layout-login-logo">
                            <Button label="Login" icon="pi pi-unlock" style={{ width: "50%" }} />
                        </div>
                    </form>
                </div>
                <AppFooter className="layout-login-footer"></AppFooter>
            </div>
        </div >
    );
};

