import * as Constants from './dbConnection'

export function dbGetTypList(options, callback) {
    // const udcQuery = "procOption=" + encodeURIComponent(options.procOption) 
    //                 + "&udcMod=" +  encodeURIComponent(options.udcMod)

    const httpQuery =  options.query   ;
    const accessToken = localStorage.getItem('accessToken');
    const httpHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Authorization',
        'Authorization': `Bearer ${accessToken}`
    }

    const requestOptions = {
        method: 'GET',
        headers: httpHeaders,
    };

    fetch(Constants.WSURL + '/ref/expensetype?'+httpQuery, requestOptions)
        .then(res => res.json())
        .then(result => {
            callback(result);
        })
        .catch(rejected => {
            callback({err:rejected});
        });
    }

