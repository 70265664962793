
import React, { useContext, useState, useEffect, useRef } from 'react';
import { dbGetSpend } from '../services/SpendService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
// import { MultiSelect } from 'primereact/multiselect';
// import { Tooltip } from 'primereact/tooltip';

// import classnames from 'classnames';
import { Dropdown } from 'primereact/dropdown';

import { LoginContext } from '../contexts/LoginContext';


export default function AnpRequestGridPending() {
    const { setProfile, profile } = useContext(LoginContext);

    // const [selectedCustomers, setSelectedCustomers] = useState(null);
    let selectedCustomers = null 
    const [spend, setSpend] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [recStatus, setRecStatus] = useState(null);

    // const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    // const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

    const dt = useRef(null);
    // Format Table

    // const header = (
    //     <div className="table-header">
    //         A&P Request
    //         {/* <Button icon="pi pi-refresh" /> */}
    //     </div>
    // );

    const header = (
        <div className="table-header">
            A&P Request Pending My Approval
            <div className="p-tablebuttonset">
                {/* <Button type="button" icon="pi pi-plus" className="p-button-rounded p-button-success" onClick={() => window.location = "#/anpRequest"} tooltip="Add New Request" /> */}
                {/* <Button type="button" icon={showFilter ? "pi pi-filter-slash" : "pi pi-filter"} className="p-button-rounded p-button-warning" onClick={() => setShowFilter(!showFilter)} tooltip="Toggle Filter" /> */}
                <Button type="button" icon="pi pi-refresh" className="p-button-rounded p-button-success" onClick={(e) => tableRefresh(e)} tooltip="Refresh" />
            </div>
        </div>
    );

    const dateBodyTemplate = (rowData, field) => {
        if (rowData[`${field}`] === undefined) {
            return null
        } else {
            let dateWithouthSecond = new Date(rowData[`${field}`]);
            return dateWithouthSecond.toLocaleString("en-GB", { dateStyle: "medium" })
        }
    }

    const filterFromBackend = () => {
        alert('go get new data from database');
        return false
    }



    const amountBodyTemplate = (rowData, field) => {
        let value = rowData[`${field}`]
        if (value === undefined)
            return null
        return value.toLocaleString(undefined,
            { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 });
    }

    const recStatusBodyTemplate = (rowData) => {
        switch (rowData.recStatus) {
            case 'N':
                return <Button type="button" icon="pi pi-book" className="p-button-text p-button-secondary" tooltip="New" />
            case 'AP':
                return <Button type="button" icon="pi pi-question" className="p-button-text p-button-warning" tooltip="Pending Approval" />
            case 'A':
                return <Button type="button" icon="pi pi-thumbs-up" className="p-button-text p-button-success" tooltip="Approved" />
            case 'R':
                return <Button type="button" icon="pi pi-thumbs-down" className="p-button-text p-button-danger" tooltip="Rejected" />
            case 'X':
                return <Button type="button" icon="pi pi-trash" className="p-button-text  p-button-warning" tooltip="Cancelled" />
            case 'C':
                return <Button type="button" icon="pi pi-dollar" className="p-button-text p-button-info" tooltip="Closed" />
            default:
                return <span>{rowData.recStatus}</span>;
        }
    }

    let recStatuses = [
        { label: 'All Status', value: null },
        { label: 'New', value: 'N' },
        { label: 'Pending', value: 'AP' },
        { label: 'Approved', value: 'A' },
        { label: 'Rejected', value: 'R' },
        { label: 'Closed', value: 'C' },
        { label: 'Cancelled', value: 'X' }
    ];

    const onStatusChange = (event) => {
        dt.current.filter(event.value, 'recStatus', 'equals');
        setRecStatus(event.value);
    }

    const tableRefresh = () => {
        dbGetSpend({ query: "option=my_hdr_AP&type=list" }, (result) => {
            if (result.status === 201) {
                // console.log(result.data)
                setSpend(result.data);
            }
        });
    }

    const onRowDblclick = (e) => {
        // alert("Hello : " + e.data.id)
        console.log("onRowDblclick", e.data)
        window.location = "#/anpRequest?id=" + e.data.id
    }

    let statusFilter = <Dropdown style={{ width: '100%' }} className="ui-column-filter"
        value={recStatus} options={recStatuses} onChange={onStatusChange} />

    useEffect(() => {
        setProfile({ ...profile, activeMenuName: "A&P List" })
        tableRefresh()
    }, []);

    return (
        <div>
            <div className="p-md-12 p-sm-12">
                <div className="card card-w-title" >
                    <div className="p-grid" >
                        <div className="p-md-12 p-sm-12" >
                            <DataTable value={spend} style={{ marginBottom: '20px' }}
                                className="p-datatable-sm"
                                responsive={true}
                                paginator
                                ref={dt}
                                rowHover
                                // selection={selectedCustomers}
                                onRowDoubleClick={e => onRowDblclick(e)}
                                // filterFunction={filterFromBackend}
                                // onFilter={(e) => filterFromBackend()}
                                // paginatorLeft={paginatorLeft} 
                                // paginatorRight={paginatorRight}
                                header={header}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                                {/* <Column selectionMode="multiple" /> */}
                                <Column field="id" header="id" filter={showFilter} sortable={true} style={{ width: '80px' }} />
                                <Column field="dte" header="Date" filter={showFilter} sortable={true} body={(rowData) => dateBodyTemplate(rowData, 'dte')} style={{ width: '120px' }} />
                                <Column field="pdNme" header="Distributor" filter={showFilter} sortable={true} style={{ width: '20%' }} />
                                <Column field="cst" header="Code" filter={showFilter} sortable={true} style={{ width: '10%' }} />
                                <Column field="cstNme" header="Customer Name" filter={showFilter} sortable={true} style={{ width: '25%' }} />
                                <Column field="amt" header="Amount" filter={showFilter} sortable={true} body={(rowData) => amountBodyTemplate(rowData, 'amt')} style={{ width: '10%',  textAlign: 'right'  }} />
                                <Column field="recStatus" filter={showFilter} className="columnCenter" filterElement={statusFilter} sortable={true} style={{ width: '50px' }} body={(rowData) => recStatusBodyTemplate(rowData)} />
                                <Column field="expTypD" filter={showFilter} filterFunction={filterFromBackend} header="Expense Type" sortable={true} style={{ width: '20%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};

