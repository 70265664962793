import * as Constants from './dbConnection'

export function dbGetCstList(options, callback) {
    const httpQuery = options.query;
    const accessToken = localStorage.getItem('accessToken');
    const httpHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Authorization',
        'Authorization': `Bearer ${accessToken}`
    }

    const requestOptions = {
        method: 'GET',
        headers: httpHeaders,
    };

    // console.log("httpQuery", httpQuery)

    fetch(Constants.WSURL + '/ref/customer?' + httpQuery, requestOptions)
        .then(res => res.json())
        .then(result => {
            callback(result);
        })
        .catch(rejected => {
            callback({ err: rejected });
        });
}
