import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
// import * as Constants from '../services/dbConnection'

import { Password } from "primereact/password";

import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import { dbGetPDList } from "../services/DistributorService";
import { dbGetUdc } from "../services/UdcService";
import { dbGetUser, dbSetUser } from "../services/UserService";

import classnames from "classnames";

// import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from "primereact/inputnumber";
// import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

// import { Calendar } from 'primereact/calendar';
import { AutoComplete } from "primereact/autocomplete";

import { Button } from "primereact/button";

// import { AppFooter } from '../AppFooter';

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { LoginContext } from "../contexts/LoginContext";
import { Dropdown } from "primereact/dropdown";
import { dbGetUserMgrList } from "../services/UserService";

export default function CWUser(props) {
  const history = useHistory();

  const toast = useRef(null);
  const { profile, setProfile } = useContext(LoginContext);
  const [mgrFiltered, setMgrFiltered] = useState(null);

  const defaultValues = {
    usrNme: "",
    recStatus: "N",
    recCreatedBy: null,
    recCreatedOn: null,
    recModifiedBy: null,
    recModifiedOn: null,
  };

  const secMenu = [
    { name: "Dashboard", key: "1" },
    { name: "Spend Request", key: "2" },
    { name: "My Approval", key: "3" },
    { name: "Spend JV", key: "4" },
    { name: "Users", key: "5" },
  ];

  const [selectedSecMenu, setSelectedSecMenu] = useState([]);

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm({ defaultValues });
  // const [formShowMessage, setFormShowMessage] = useState(false);

  const [rec, setRec] = useState({
    id: null,
    usrNme: "",
    recStatus: "N",
    readOnly: false,
    showFinanceAppr: false,
    showFinanceDiv: false,
  });
  const [activeTable, setActiveTable] = useState({});

  // const [recID, setRecID] = useState(-1) ;

  const [pdOptions, setPD] = useState({});
  const [usrRoleOptions, setUserRole] = useState({});

  // const [recActionReason, setRecActionReason] = useState() ;
  const [recAction, setRecAction] = useState();

  const [showDelRowDialog, setShowDelRowDialog] = useState(false);

  // const [showDelFileDialog, setShowDelFileDialog] = useState(false);
  // const [showReasonDialog, setShowReasonDialog] = useState(false);

  const getRecStatus = (recStatus) => {
    switch (recStatus) {
      case "N":
        return <i className="pi pi-book p-icon-secondary"> New </i>;
      case "AP":
        return (
          <i className="pi pi-question p-icon-warning"> Pending Approval </i>
        );
      case "A":
        return <i className="pi pi-thumbs-up p-icon-success"> Approved </i>;
      case "R":
        return <i className="pi pi-thumbs-down p-icon-danger"> Rejected </i>;
      case "X":
        return <i className="pi pi-trash p-icon-warning"> Cancelled </i>;
      case "C":
        return <i className="pi pi-lock p-icon-info"> Closed </i>;
      case "MA":
        return <i className="pi pi-lock p-icon-info"> Open </i>;
      case "MC":
        return <i className="pi pi-lock p-icon-info"> Close</i>;
      default:
        return <span>{recStatus}</span>;
    }
  };

  //
  // Form Control -------------------------------------------------------------------------
  //
  const onActionButtonClick = (action) => {
    // alert("action " + action)
    setRecAction(action);
    // if (action === 'Reject' || action === "Close"  || action === "Cancel" || action === "Cancel_Undo") {
    //     setShowReasonDialog(true)
    // }
    // else
    if (action === "PageBack") {
      history.goBack();
    } else {
      setValue("action", action);
    }
  };

  const onSubmit = (data) => {
    data.secMenu = selectedSecMenu;
    if (data.usrPwdNew !== "****************") {
      data.usrPwd = data.usrPwdNew;
      data.usrPwdNew = "Changed";
    }

    dbSetUser(data, (result) => {
      if (data.id === undefined) {
        data.id = result.recID;
        // setRecID(result.recID)
      }

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "A&P Request " + data.id + " saved",
        life: 3000,
      });

      reset({});

      history.goBack();
    });
    // }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  // Autocorrect - Manager ID  ---------------------------------------------------------------------------------------------
  //
  const mgrSearch = (event) => {
    const queryValue = event.query;
    setTimeout(() => {
      dbGetUserMgrList(
        {
          query:
            "type=mgr&rowLimit=10&filter=usrMgr like '^" + queryValue + "^' ",
        },
        (result) => {
          if (result.status === 201) {
            setMgrFiltered(result.data);
          }
        }
      );
    }, 250);
  };

  //
  // Grid Table --------------------------------------------------------------------------------
  //
  const dropdownEditor = (inputFieldName, props, field) => {
    console.log("props", props.rowData);
    console.log("field", field);
    return (
      <Dropdown
        id={props.rowData["usrPd"]}
        // value={props.rowData['usrPd']}
        style={{ width: "100%" }}
        options={pdOptions}
        optionLabel="name"
        // optionValue="name"
        disabled={rec.readOnly}
        onChange={(e) => onDropdownValueChange(inputFieldName, props, e)}
      />
    );
  };

  const onDropdownValueChange = (inputFieldName, props, e) => {
    let updatedProducts = [props.props.value];
    updatedProducts[0][props.rowIndex][props.field] = e.value;

    console.log("updated product : ", updatedProducts);
    console.log("updated e : ", e);
    return e.value;
  };

  // const onFieldValueChange = (fieldName, props, e) => {
  //     alert("fieldbvaluechange")
  //     return e.value;
  // }

  const onCheckboxValueChange = (fieldName, props, e) => {
    setValue(fieldName, e.checked ? 1 : 0);
    return e.checked;
  };

  const onSecMenuChange = (e) => {
    let _selectedSecMenu = [...selectedSecMenu];

    if (e.checked) _selectedSecMenu.push(e.value);
    else
      _selectedSecMenu = _selectedSecMenu.filter(
        (secMenu) => secMenu.key !== e.value.key
      );

    setSelectedSecMenu(_selectedSecMenu);
  };

  const header_pd = (
    <div>
      <Button
        type="button"
        icon="pi pi-plus"
        className="p-button-rounded"
        onClick={() => gridAddRow("sec")}
      />
    </div>
  );

  const confirmDeleteSelected = (rowData, tableName) => {
    setActiveTable({ tableName: tableName, rowData: rowData });

    setShowDelRowDialog(true);
  };

  const gridAddRow = (props) => {
    // alert("grid Add")
    let data = getValues(props);
    let idx = data.length + 10;
    data = data.concat({ id: idx });
    setValue(props, data);
  };

  // const reasonExpenses = (data) => {
  //     // setShowReasonDialog(false) ;

  //     setValue("action", recAction)
  //     // setValue("recActionReason", recActionReason)

  //     handleSubmit(onSubmit)() ;

  // }

  const gridDelRow = () => {
    // alert("delete from " + activeTable.)
    let tblData = getValues(activeTable.tableName);
    // console.log('delete row', activeTable.rowData)
    // console.log('delete', tblData)

    let newTable = tblData.filter((item) => item.id !== activeTable.rowData.id);

    // console.log('new data', newTable)

    setValue(activeTable.tableName, newTable);
    // if (activeTable.tableName === 'spend')

    //     calcRowTotal();

    setShowDelRowDialog(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Row Deleted",
      life: 1500,
    });
  };

  const actionBodyTemplate = (rowData, tableName) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteSelected(rowData, tableName)}
        />
      </React.Fragment>
    );
  };

  const deleteRowDialogFooter = () => {
    return (
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => {
            setShowDelRowDialog(false);
          }}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={gridDelRow}
        />
      </React.Fragment>
    );
  };

  const dbGetUserData = async (id) => {
    let data = {};
    data.sec = [];

    await dbGetUser({ query: "option=dtl&type=id", path: id })
      .then((result) => {
        if (result.status === 201) {
          data = result.data[0];
          data.sec = [];
          // data.usrNme = "" ;
          data.usrPwdNew = "****************";

          let secArray = [];
          // let i = 0 ;

          Array.from(data.usrMenu).forEach((character, i) => {
            if (character === "1") {
              secArray = [...secArray, secMenu[i]];
            }
          });

          setSelectedSecMenu(secArray);

          console.log("result data : ", result, data);
          setRec({
            id: data.id,
            recStatus: data.recStatus,
            readOnly: false,
            // readOnly: (data.recStatus === 'N' || data.recStatus === 'R') ? false : true,
          });
        } else if (result.status === 204) {
          console.log("no thing");
          data.sec = [];
          data.usrNme = "";
          data.usrPwd = "";

          setRec({
            id: 0,
            usrNme: "",
            recStatus: "N",
            readOnly: false,
            // readOnly: (data.recStatus === 'N' || data.recStatus === 'R') ? false : true,
          });
        }
      })
      .catch((rejected) => {
        console.log("User Details Rejected : ", rejected);
      });

    await dbGetUser({
      query: "option=usrSec&type=rec&filter=hdr_id = '" + data.id + "'",
    })
      .then((result) => {
        // console.log("User Details (Security) : ", result)
        if (result.status === 201) {
          data.sec = result.data;
          // console.log("User Details (Security) : ", data, data.id)
        }
      })
      .catch((rejected) => {
        console.log("User Details (Security) Rejected : ", rejected);
      });

    console.log("data ", data);
    reset(data);
  };

  // const recStatusBodyTemplate = (rowData) => {
  //         switch (rowData.recStatus) {
  //             case 'N':
  //                 return <Button type="button" icon="pi pi-book" className="p-button-text p-button-secondary" tooltip="New" />
  //             case 'AP':
  //                 return <Button type="button" icon="pi pi-question" className="p-button-text p-button-warning" tooltip="Pending Approval" />
  //             case 'A':
  //                 return <Button type="button" icon="pi pi-thumbs-up" className="p-button-text p-button-success" tooltip="Approved" />
  //             case 'R':
  //                 return <Button type="button" icon="pi pi-thumbs-down" className="p-button-text p-button-danger" tooltip="Rejected" />
  //             case 'X':
  //                 return <Button type="button" icon="pi pi-trash" className="p-button-text  p-button-warning" tooltip="Cancelled" />
  //             case 'C':
  //                 return <Button type="button" icon="pi pi-lock" className="p-button-text p-button-info" tooltip="Closed" />
  //             case 'MA':
  //                 return <Button type="button" icon="pi pi-lock" className="p-button-text p-button-danger " tooltip="Close" />
  //             case 'MC':
  //                 return <Button type="button" icon="pi pi-lock-open" className="p-button-text p-button-info" tooltip="Open" />
  //             default:
  //                 return <span>{rowData.recStatus}</span>;
  //         }
  //     }
  /* upload attachment */
  // const onUpload = () => {
  //     toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
  // }

  // const chooseOptions = {label: 'Add', icon: 'pi pi-fw pi-plus'};

  useEffect(() => {
    setProfile({ ...profile, activeMenuName: "A&P Request" });

    const search = props.location.search;
    let id = new URLSearchParams(search).get("id");
    if (id === null) id = 0;

    dbGetUdc(
      { query: "option=usrRole&type=list&filter=recStatus = 'A'" },
      (result) => {
        if (result.status === 201) {
          // console.log("udc", result.data)
          setUserRole(result.data);
        } else {
          console.log("result.status", result.status);
        }
      }
    );

    dbGetPDList({ query: "type=list" }, (result) => {
      if (result.status === 201) {
        setPD(result.data);
      }
    });

    dbGetUserData(id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>
                  User
                  <span className="layout-h1">
                    {" "}
                    [Record ID -{rec.id} ] {getRecStatus(rec.recStatus)}{" "}
                  </span>
                  <div className="p-actionbuttonset">
                    {rec.recStatus === "N" || rec.recStatus === "A" ? (
                      <Button
                        type="submit"
                        icon="pi pi-save"
                        className="p-button-rounded"
                        onClick={() => onActionButtonClick("Save")}
                      />
                    ) : null}
                    {rec.recStatus === "A" ? (
                      <Button
                        type="submit"
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning"
                        onClick={() => onActionButtonClick("Delete")}
                      />
                    ) : null}
                    {rec.recStatus === "I" ? (
                      <Button
                        type="button"
                        icon="pi pi-replay"
                        className="p-button-rounded p-button-danger"
                        onClick={() => onActionButtonClick("Cancel_Undo")}
                      />
                    ) : null}
                    <Button
                      type="button"
                      icon="pi pi-chevron-left"
                      className="p-button-rounded p-button-warning"
                      onClick={() => onActionButtonClick("PageBack")}
                    />
                  </div>
                </h1>
                <div className="p-grid">
                  <div className="p-md-6 p-sm-12">
                    {" "}
                    {/*User ID*/}
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="usrID"
                          control={control}
                          rules={{ required: "User ID is required." }}
                          render={({ field, fieldState }) => (
                            <AutoComplete
                              id={field.name}
                              minLength={1}
                              {...field}
                              field="label"
                              disabled={rec.readOnly}
                              // completeMethod={cstSearch}
                              // suggestions={cstFiltered}
                              onChange={(e) => field.onChange(e.value)}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="usrID"
                          className={classnames({ "p-error": errors.name })}
                        >
                          User ID*
                        </label>
                      </span>
                      {getFormErrorMessage("usrID")}
                    </div>
                  </div>

                  <div className="p-md-6 p-sm-12">
                    {" "}
                    {/*User email*/}
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="usrEMail"
                          control={control}
                          rules={{ required: "email is required." }}
                          render={({ field, fieldState }) => (
                            <AutoComplete
                              id={field.name}
                              minLength={1}
                              {...field}
                              field="label"
                              disabled={rec.readOnly}
                              // completeMethod={cstSearch}
                              // suggestions={cstFiltered}
                              onChange={(e) => field.onChange(e.value)}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="usrEMail"
                          className={classnames({ "p-error": errors.name })}
                        >
                          {" "}
                          eMail Address *
                        </label>
                      </span>
                      {getFormErrorMessage("usrEMail")}
                    </div>
                  </div>

                  {/* <div className="p-md-6 p-sm-12"> */}
                  {/* {" "} */}
                  {/* User Name
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="usrNmeN"
                          control={control}
                          rules={{ required: "User Name is required." }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              minLength={3}
                              {...field}
                              // field="label"
                              disabled={rec.readOnly}
                              // completeMethod={cstSearch}
                              // suggestions={false}
                              onChange={(e) => field.onChange(e.value)}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="usrNme"
                          className={classnames({ "p-error": errors.name })}
                        >
                          User Name*
                        </label>
                      </span>
                      {getFormErrorMessage("usrNmeN")}
                    </div>
                  </div> */}

                  <div className="p-md-6 p-sm-12">
                    {/*User Name*/}
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="usrNme"
                          control={control}
                          rules={{ required: "Manager id is required." }}
                          render={({ field, fieldState }) => (
                            <AutoComplete
                              id={field.name}
                              minLength={1}
                              {...field}
                              field="label"
                              disabled={rec.readOnly}
                              // completeMethod={cstSearch}
                              // suggestions={cstFiltered}
                              onChange={(e) => field.onChange(e.value)}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="usrNme"
                          className={classnames({ "p-error": errors.name })}
                        >
                          User Name *
                        </label>
                      </span>
                      {getFormErrorMessage("usrNme")}
                    </div>
                  </div>

                  <div className="p-md-6 p-sm-12">
                    {/*User Manager email*/}
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="usrMgr"
                          control={control}
                          rules={{ required: "Customer is required." }}
                          render={({ field, fieldState }) => (
                            <AutoComplete
                              id={field.name}
                              minLength={1}
                              {...field}
                              field="label"
                              disabled={rec.readOnly}
                              completeMethod={mgrSearch}
                              suggestions={mgrFiltered}
                              onChange={(e) => field.onChange(e.value)}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="usrMgr"
                          className={classnames({ "p-error": errors.name })}
                        >
                          Manager ID *
                        </label>
                      </span>
                      {getFormErrorMessage("usrMgr")}
                    </div>
                    {/* <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="usrMgr"
                          control={control}
                          rules={{ required: "Manager id is required." }}
                          render={({ field, fieldState }) => (
                            <AutoComplete
                              id={field.name}
                              minLength={1}
                              {...field}
                              field="label"
                              disabled={rec.readOnly}
                              // completeMethod={cstSearch}
                              // suggestions={cstFiltered}
                              onChange={(e) => field.onChange(e.value)}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="usrMgr"
                          className={classnames({ "p-error": errors.name })}
                        >
                          Manager id *
                        </label>
                      </span>
                      {getFormErrorMessage("usrMgr")}
                    </div> */}
                  </div>

                  <div className="p-md-6 p-sm-12">
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="usrPwdNew"
                          control={control}
                          rules={{ required: "Password is required." }}
                          render={({ field, fieldState }) => (
                            <Password
                              id={field.name}
                              minLength={3}
                              {...field}
                              inputRef={field.ref}
                              disabled={rec.readOnly}
                              toggleMask
                              // feedback={false}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="usrPwdNew"
                          className={classnames({ "p-error": errors.name })}
                        >
                          Password*
                        </label>
                      </span>
                      {getFormErrorMessage("usrPwdNew")}
                    </div>
                    `
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Access Control Card  */}
        <div className="p-md-12 p-sm-12">
          <div className="card card-w-title">
            <h1>Access Control</h1>
            <div className="p-grid">
              <div className="p-md-6 p-sm-12">
                <Controller
                  name="sec"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DataTable
                      disabled={rec.readOnly}
                      value={field.value}
                      selectionMode="single"
                      // header={headerTemplate}
                      // footer={footerTemplate}
                    >
                      <Column
                        field="usrPD"
                        header="Distributor"
                        sortable={true}
                        style={{ width: "80%" }}
                        editor={(props) =>
                          dropdownEditor("usrPD", props, field)
                        }
                      />
                      {rec.readOnly ? null : (
                        <Column
                          header={header_pd}
                          body={(rowData) => actionBodyTemplate(rowData, "sec")}
                        ></Column>
                      )}
                    </DataTable>
                  )}
                />
              </div>

              <div className="p-md-6 p-sm-12">
                <div className="p-grid">
                  <div className="p-md-4 p-sm-12">
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="usrRole"
                          control={control}
                          rules={{ required: "User Role is required." }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.name}
                              value={field.value}
                              disabled={rec.readOnly}
                              onChange={(e) => field.onChange(e.value)}
                              options={usrRoleOptions}
                              optionLabel="label"
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        {getFormErrorMessage("usrRole")}
                        <label htmlFor="typ">User Role</label>
                      </span>
                    </div>
                    <h3>Budget</h3>
                    <div className="p-field div-name">
                      <span>
                        <Controller
                          name="usrBudget"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Checkbox
                              id={field.name}
                              field="label"
                              // disabled={rec.readOnly}
                              checked={field.value === 1}
                              // completeMethod={cstSearch}
                              // suggestions={cstFiltered}
                              onChange={(e) =>
                                onCheckboxValueChange(field.name, props, e)
                              }
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="usrBudget"
                          className={classnames({ "p-error": errors.name })}
                        >
                          {" "}
                          Budget Holder{" "}
                        </label>
                      </span>
                    </div>
                    <div className="p-field div-name">
                      <span className="p-float-label">
                        <Controller
                          name="usrLmt"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              minLength={1}
                              {...field}
                              disabled={rec.readOnly}
                              mode="decimal"
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              onChange={(e) => field.onChange(e.value)}
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="usrLmt"
                          className={classnames({ "p-error": errors.name })}
                        >
                          Approval Limit
                        </label>
                      </span>
                    </div>
                    <div>
                      <span>
                        <Controller
                          name="usrViewBudgetCom"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Checkbox
                              id={field.name}
                              field="label"
                              // disabled={rec.readOnly}
                              checked={field.value === 1}
                              // completeMethod={cstSearch}
                              // suggestions={cstFiltered}
                              onChange={(e) =>
                                onCheckboxValueChange(field.name, props, e)
                              }
                              className={classnames({
                                "p-invalid": fieldState.invalid,
                              })}
                            />
                          )}
                        />
                        <label
                          htmlFor="usrViewBudgetCom"
                          className={classnames({ "p-error": errors.name })}
                        >
                          {" "}
                          View Company Budget
                        </label>
                      </span>
                    </div>
                    <h3>Menu</h3>
                    <div>
                      {secMenu.map((secMenu) => {
                        return (
                          <Controller
                            name="secMenu"
                            control={control}
                            render={({ field, fieldState }) => (
                              <div key={secMenu.key} className="checkbox_ml">
                                <Checkbox
                                  inputId={secMenu.key}
                                  name="category"
                                  value={secMenu}
                                  onChange={onSecMenuChange}
                                  checked={selectedSecMenu.some(
                                    (item) => item.key === secMenu.key
                                  )}
                                />
                                <label
                                  htmlFor={secMenu.key}
                                  className="checkbox_ml_label"
                                >
                                  {secMenu.name}
                                </label>
                              </div>
                            )}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Dialog
        visible={showDelRowDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteRowDialogFooter}
        onHide={showDelRowDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete?</span>}
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
}
