
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

import { DBGetAnp, DBSetAnp } from '../services/SpendService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar';

import { LoginContext } from '../contexts/LoginContext';
 import { saveAs } from 'file-saver';

export default function AnpRequestJVGrid() {
    // const { setProfile, profile } = useContext(LoginContext);
    const { profile, setProfile } = useContext(LoginContext);

    const [ jvPeriod, setJVPeriod] = useState(new Date());

    // const [selectedCustomers, setSelectedCustomers] = useState(null);
    // let selectedCustomers = null
    const [spend, setSpend] = useState([]);
    // const [spendChart, setSpendChart] = useState([]);


    // const [showFilter, setShowFilter] = useState(false);
    // const [recStatus, setRecStatus] = useState(null);
    // console.log("recStatus ", recStatus)
    const [recBatch, setRecBatch] = useState('')

    const dt = useRef(null);

    const toast = useRef(null);

    const setBatchValue = (value) => {
        setRecBatch(value);
    }

    const generateJV = () => {
        console.log('gen JV')
        var data = {}
        data.procName = "anp_JVRelease"
        data.action = "Generate"
        data.rec = { jvMM : jvPeriod.getMonth() + 1, jvYY :jvPeriod.getFullYear() }

        DBSetAnp(data, { query: "option=Generate&type=list" })
            .then((result) => {
                console.log("result", result)
                if (result.status === 201) {
                    setRecBatch(result.data[0].JVNo)
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'JV Batch Generated : ' + result.data[0].JVNo, life: 3000 });
                }
                tableRefresh()
            })
            .catch((rejected) => { console.log("rejected") })
    }

    const exportExcel = () => {
        if (spend.length > 0) {
            import('xlsx').then(xlsx => {
                const worksheet = xlsx.utils.json_to_sheet(spend);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'spend');
            });
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No JV to export', life: 3000 });
        }
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }
 
    // const chartOptions = {
    //   plugins: {
    //     datalabels: {
    //       display: true,
    //       color: "black",
    //       formatter: Math.round,
    //       anchor: "end",
    //       offset: -20,
    //       align: "start"
    //     }
    //   },
    //   legend: {
    //     display: false
    //   }
    // };


    // const months = [
    //     {label: 'Jan', value: '1'},
    //     {label: 'Feb', value: '2'},
    //     {label: 'Mar', value: '3'},
    //     {label: 'Apr', value: '4'},
    //     {label: 'May', value: '5'}
    // ]

    // const onJVMonthChange = (e) => {
    //     setJVMonth(e.value);
    // }

    const header = (
        <div className="table-header">
            A&P Journal Entry Listing
            <div className="p-tablebuttonset">

                <label htmlFor="jvPeriod">JV Period </label>
                <Calendar id="jvPeriod" style={{width:'14em'}} view="month" dateFormat="M-yy" value={jvPeriod} onChange={(e) => setJVPeriod(e.value)} />
                <label htmlFor="recBatch">  Batch No </label>
                <InputText id="recBatch" value={recBatch} style={{width:'5em'}} onChange={(e) => setBatchValue(e.target.value)} />
                <Button type="button" label="" icon="pi pi-refresh" className="p-button-rounded p-button-success" onClick={(e) => tableRefresh(e)}  />  
                <span> </span>
                <Button type="button" label="" icon="pi pi-ticket" className="p-button-rounded p-button-warning" onClick={(e) => generateJV(e)}   />
                <Button type="button" label="" icon="pi pi-file-excel" className="p-button-rounded p-button-warning" onClick={(e) => exportExcel(e)} />
            </div>
        </div>
    );

    // const dateBodyTemplate = (rowData, field) => {
    //     if (rowData[`${field}`] === undefined) {
    //         return null
    //     } else {
    //         let dateWithouthSecond = new Date(rowData[`${field}`]);
    //         return dateWithouthSecond.toLocaleString("en-GB", { dateStyle: "medium" })
    //     }
    // }

    // const filterFromBackend = () => {
    //     // alert('go get new data from database');
    //     return false
    // }

    const amountBodyTemplate = (rowData, field) => {
        let value = rowData[`${field}`]
        if (value === undefined)
            return null
        return value.toLocaleString(undefined,
            { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 });
    }

    // const recStatusBodyTemplate = (rowData) => {
    //     switch (rowData.recStatus) {
    //         case 'N':
    //             return <Button type="button" icon="pi pi-book" className="p-button-text p-button-secondary" tooltip="New" />
    //         case 'AP':
    //             return <Button type="button" icon="pi pi-question" className="p-button-text p-button-warning" tooltip="Pending Approval" />
    //         case 'A':
    //             return <Button type="button" icon="pi pi-thumbs-up" className="p-button-text p-button-success" tooltip="Approved" />
    //         case 'R':
    //             return <Button type="button" icon="pi pi-thumbs-down" className="p-button-text p-button-danger" tooltip="Rejected" />
    //         case 'X':
    //             return <Button type="button" icon="pi pi-trash" className="p-button-text  p-button-warning" tooltip="Cancelled" />
    //         case 'C':
    //             return <Button type="button" icon="pi pi-dollar" className="p-button-text p-button-info" tooltip="Closed" />
    //         default:
    //             return <span>{rowData.recStatus}</span>;
    //     }
    // }

    // let recStatuses = [
    //     { label: 'All Status', value: null },
    //     { label: 'New', value: 'N' },
    //     { label: 'Pending', value: 'AP' },
    //     { label: 'Approved', value: 'A' },
    //     { label: 'Rejected', value: 'R' },
    //     { label: 'Closed', value: 'C' },
    //     { label: 'Cancelled', value: 'X' }
    // ];

    // const onStatusChange = (event) => {
    //     dt.current.filter(event.value, 'recStatus', 'equals');
    //     setRecStatus(event.value);
    // }

    const tableRefresh = () => {
        // var qry = (recBatch === '') ? " is null " : " = " + recBatch
        // console.log('jvPeriod', jvPeriod)
        // console.log(jvPeriod, jvPeriod.getUTCMonth(), jvPeriod.getFullYear())
        // let qry = "option=sqljv&type=list&filter=[DOCNO(20)] " + ((recBatch === '') ? " is null " : " = " + recBatch) + " and Month(jvProvDte) = " + (jvPeriod.getMonth() + 1) + ' and year(jvProvDte) = ' + jvPeriod.getFullYear()
        let qry = ''
        if (jvPeriod === null) {
            // qry = 'type=list&filter=' + " [DOCNO(20)] = " + recBatch
            qry = 'type=list&filter= [DOCNO(20)] = '+ recBatch 
    
        } else {
            qry = 'type=list&filter=' + ((recBatch === '') ? " Month(jvProvDte) = " + (jvPeriod.getMonth() + 1) + ' and year(jvProvDte) = ' + jvPeriod.getFullYear() : " [DOCNO(20)] = " + recBatch) 
    
        }
        // DBGetAnp({ query: "option=jvChart&type=chartBar&filter=1=1"  })
        //     .then((result) => {
        //         if (result.status === 201) {
        //             console.log(result.data)
        //             setSpendChart(result.data);
        //         }
        //     })
        //     .catch((rejected) => {
        //         setSpendChart([]);
        // })
        DBGetAnp({ query: "option=sqljv&" + qry })
            .then((result) => {
                if (result.status === 201) {
                    setSpend(result.data);
                }
                // result.dataChart = {}
                // let labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec','Jan']
                // result.dataChart = ({
                //     labels : labels,
                //     datasets : [{type: 'bar', label: 'New', backgroundColor: '#42A5F5', data: [
                //             0,
                //             0,
                //             0,
                //             75,
                //             37,
                //             65,
                //             34
                //         ]}]
                // });
                // result.dataChart2 = ({
                //     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec','Jan'],
                //     datasets: [ {
                //         type: 'bar',
                //         label: 'New',
                //         backgroundColor: '#42A5F5',
                //         data: [
                //             0,
                //             0,
                //             0,
                //             75,
                //             37,
                //             65,
                //             34
                //         ]
                //     }]
                // });
                // console.log("1", result.dataChart)
                // console.log("2", result.dataChart2)
                // setSpendChart(result.dataChart);
            })
            .catch((rejected) => {

                setSpend([]);
            })
    }

    // const batchNoRefresh = () => {
    //     // var qry = (recBatch === '') ? " is null " : " = " + recBatch

    //     DBGetAnp({ query: "option=jvBatchNo&type=rec" })
    //         .then((result) => {
    //             if (result.status === 201) {
    //                 setRecBatch(result.data[0].ctlNum - 1);
    //             }
    //         })
    //         .catch((rejected) => {
    //             console.log("[batchNoRefresh] Rejected")
    //         })
    // }

    // const onRowDblclick = (e) => {
    //     window.location = "#/anpRequest?id=" + e.data.id
    // }

    // let statusFilter = <Dropdown style={{ width: '100%' }} className="ui-column-filter"
    //     value={recStatus} options={recStatuses} onChange={onStatusChange} />

    useEffect(() => {
        setProfile({ ...profile, activeMenuName: "A&P Journal Entry List" })
        tableRefresh()
        // batchNoRefresh()

    },[]);

    return (
        <div>
            {/* <div className="card">
                <Chart type="bar" 
                    data={spendChart} 
                    options={chartOptions}
                    height='100%' />
            </div> */}
            <div className="p-md-12 p-sm-12">
                <div className="card card-w-title" >
                    {/* <div className="p-grid" > */}
                    {/* <div className="p-md-12 p-sm-12" > */}
                    <DataTable value={spend} style={{ marginBottom: '20px' }}
                        className="p-datatable-sm"
                        responsive={true}
                        paginator
                        ref={dt}
                        rowHover
                        header={header}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={12} rowsPerPageOptions={[10, 12, 20, 50, 100]}>
                        {/* <Column selectionMode="multiple" /> */}
                        <Column field="DOCNO(20)" header="Batch" style={{ width: '50px' }} />
                        <Column field="DOCDATE" header="Date" style={{ width: '100px' }} />
                        <Column field="DESCRIPTION_HDR(200)" header="Descriptions" style={{ width: '300px' }} />
                        <Column field="DESCRIPTION_DTL(200)" header="Descriptions Detail" style={{ width: '330px' }} />
                        <Column field="REF(25)" header="Reference" style={{ width: '140px' }} />
                        <Column field="DR" header="DR" className="columnRight" body={(rowData) => amountBodyTemplate(rowData, 'DR')} style={{ width: '110px',  textAlign: 'right' }} />
                        <Column field="CR" header="CR" className="columnRight" body={(rowData) => amountBodyTemplate(rowData, 'CR')} style={{ width: '110px',  textAlign: 'right' }} />
                        {/* <Column field="Pro" header="Pro" className="columnRight" body={(rowData) => amountBodyTemplate(rowData, 'Pro')} style={{ width: '110px' }} /> */}
                    </DataTable>
                    {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
            <Toast ref={toast} />
        </div >

    )
};

