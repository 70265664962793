import React, { useContext, useState } from 'react';
import classNames from 'classnames';

import { LoginContext } from './contexts/LoginContext';
// import EmptyPage from './components/emptyPage';

export default function AppProfile() {
    const [expanded, setExpanded] = useState(false);
    const { setAuth, profile } = useContext(LoginContext);

    // console.log("App Profile", profile.userName) ;

    const onClickProfile = (event) => {
        setExpanded(!expanded);
        event.preventDefault();
    }

    // const onClickProfileMenu = (event) => {
    //     window.location = "#/emptyPage"
    // }

    const onClickLogout = (event) => {
        setAuth(false);
    }
    return (
        <div className="layout-profile">
            <div>
                <img src="assets/layout/images/profile.png" alt="" />
            </div>
            <button className="p-link layout-profile-link" onClick={onClickProfile}>
                <span className="username">{profile.userName}</span>
                <i className="pi pi-fw pi-cog" />
            </button>
            <ul className={classNames({ 'layout-profile-expanded': expanded })}>
                {/* <li><button className="p-link" onClick={onClickProfileMenu}><i className="pi pi-fw pi-user" /><span>Account</span></button></li> */}
                {/* <li><button className="p-link"><i className="pi pi-fw pi-inbox"/><span>Notifications</span><span className="menuitem-badge">2</span></button></li> */}
                <li><button className="p-link" onClick={onClickLogout}><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></li>
            </ul>
        </div>
    );
}